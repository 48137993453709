import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Field } from "formik";
import OutsideClickHandler from "react-outside-click-handler";
import * as Yup from "yup";
import API from "../../../../api";
import PhoneNumber from "../../../Common/PhoneNumber/PhoneNumber";
import {
  getCitiesAsync,
  getStateAsync,
} from "../../../../redux/slices/AuthSlice";
import { linkedInUrlRegex, nameRegex, urlRegex } from "../../../../constants";
import { getJobTypesDataAsync } from "../../../../redux/slices/JobSearchSlice";

const CanProfileForm = ({
  loader,
  baseInfo,
  jobTitles,
  tags,
  companyLogo,
  maskedPlaceHolderP,
  maskedPhoneNumberP,
  statesCount,
  editHandler,
  companyLogoModelHandler,
  submitHandler,
  deleteImageLogo
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [allJobTitles, setAllTobTitles] = useState([]);
  const [selectJobTitles, setSelectJobTitles] = useState([]);
  const [allJobTitlesHide, setAllJobTitlesHide] = useState(true);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [currencyOption, setCurrencyOption] = useState([]);

  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationHide, setLocationHide] = useState(true);

  const [stateDDHide, setStateDDHide] = useState(true);
  const [stateSearch, setStateSearch] = useState("");

  const [cityDDHide, setCityDDHide] = useState(true);
  const [citySearch, setCitySearch] = useState("");

  const [phoneNumberValue, setPhoneNumberValue] = useState(null);
  const [allWorkPreference, setAllWorkPreference] = useState([]);
  const [selectWorkPreferenceTags, setSelectWorkPreferenceTags] = useState([]);
  const [allWorkPreferenceTagsHide, setAllWorkPreferenceTagsHide] =
    useState(true);
  const workPreferenceArray = [];
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);

  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [maskedPlaceHolder, setMaskedPlaceHolder] = useState("");

  const formik = useFormik({
    initialValues: {
      file: null,
      firstName: "",
      lastName: "",
      country: "",
      countryId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      email: "",
      phoneNo: "",
      linkedinURL: "",
      aboutSelf: "",
      interested: "",
      resume: "",
      introVideo: null,
      jobTitle: "",
      skillTags: "",
      salaryRange: "year",
      salaryExpectation: "",
      userWorkPreferences: [],
      currency: "",
      currencyId: null,
      workPreference: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(nameRegex, "First name should be an alphabet")
        .max(50, "First name cannot exceed 50 characters")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(nameRegex, "Last name should be an alphabet")
        .max(50, "Last name cannot exceed 50 characters")
        .required("Please enter last name"),
      country: Yup.string().required("Please select the country"),
      state: Yup.string(),
      city: Yup.string(),
      phoneNo: Yup.string()
        .nullable() 
        .notRequired() 
        .test(
          "complete-phone",
          "Please enter complete phone number",
          (value) => !value || !value.includes("_") 
        ),
      linkedinURL: Yup.string().matches(
        linkedInUrlRegex,
        "Please enter a valid linkedin url"
      ),
      files: Yup.mixed()
        .test(
          "fileType",
          "File can be a JPEG or PNG document",
          (value) => !value || ["image/png", "image/jpeg"].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 10 MB",
          (value) => !value || value?.size <= 10 * 1024 * 1024
        ),

      introVideo: Yup.mixed()
        .test(
          "fileType",
          "Unsupported file format",
          (value) =>
            !value ||
            [
              "video/mp4",
              "video/webm",
              "video/x-matroska",
              "video/3gpp",
              "video/quicktime", 
            ].includes(value?.type)
        )
        .test(
          "fileSize",
          "The file size should not exceed 25 MB",
          (value) => !value || value?.size <= 25 * 1024 * 1024
        )     
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      const { state, city } = data;

      if (stateOptions?.length > 0 && state === "") {
        formik?.setFieldError("state", "Please select state");
        return;
      }
      if (citiesOptions?.length > 0 && city === "") {
        formik?.setFieldError("city", "Please select city");
        return;
      }

      const selectJobTitlesNames = selectJobTitles?.map((item) => item.id);
      const selectSkillTagsNames = selectSkillTags.map((item) => item.id);
      const selectWorkPreferenceNames = selectWorkPreferenceTags.map(
        (item) => item.id
      );

      // user["currency_id"] = data.currencyId
      submitHandler(
        data,
        user,
        selectJobTitlesNames,
        selectSkillTagsNames,
        selectWorkPreferenceNames,
        () => {}
      );
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isFileFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFileErrorMessage = (name) => {
    return (
      isFileFieldValid(name) && (
        <p className="file-error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleImageChange = async (event) => {
    companyLogoModelHandler(true);
  };

  const countryHandler = async (item) => {
    const {
      id,
      name,
      currency,
      masked_phone_number,
      placeholder,
      states_count,
    } = item;

    await formik.setFieldValue("country", name);
    await formik.setFieldValue("countryId", id);

    await formik.setFieldValue("state", "");
    await formik.setFieldValue("stateId", null);

    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);

    onCurrencyClick(currency?.id, currency?.title);

    setMaskedPhoneNumber(masked_phone_number);
    setMaskedPlaceHolder(placeholder);
    setLocationHide(true);

    setStateOptions([]);
    setCitiesOptions([]);

    if (states_count === 0) {
      getCitiesData(id, null);
    } else {
      getStatusData(id);
    }
  };

  const stateHandler = async (id, name) => {
    setStateDDHide(true);
    setStateSearch(name);

    await formik.setFieldValue("state", name);
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("city", "");
    await formik.setFieldValue("cityId", null);
    getCitiesData(null, id);
  };

  const cityHandler = async (id, name) => {
    setCityDDHide(true);
    setCitySearch(name);

    await formik.setFieldValue("city", name);
    await formik.setFieldValue("cityId", id);
  };

  /* State search */

  const filteredStateItem = stateOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const searchStateLocation = (e) => {
    setStateSearch(e.target.value);
  };

  /********************/

  /* City search */

  const filteredCityItem = citiesOptions?.filter((item) =>
    item?.name?.toLowerCase().includes(citySearch.toLowerCase())
  );

  const searchCityLocation = (e) => {
    setCitySearch(e.target.value);
  };

  /********************/

  const getJobDropDownData = () => {
    const salaryRange = [
      {
        label: "Year",
        page: "",
        value: "year",
      },
      {
        label: "Month",
        page: "",
        value: "month",
      },
      {
        label: "Hour",
        page: "",
        value: "hour",
      },
    ];

    setSalaryRangeOption(salaryRange);
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const getWorkPreferences = async () => {
    try {
      dispatch(getJobTypesDataAsync())
        .then((response) => {
          const res = response?.payload;
          if (Array.isArray(res)) {
            setAllWorkPreference(res); 
          }  
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatusData = async (id) => {
    dispatch(
      getStateAsync({
        id: id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setStateOptions(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCitiesOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (companyLogo !== null) {
      formik.setFieldValue("files", companyLogo);
      setSelectedImageName(companyLogo?.name);
    }
  }, [companyLogo]);

  useEffect(() => {
    if (baseInfo?.workPreferences?.length > 0) {
      setSelectWorkPreferenceTags(baseInfo?.workPreferences);
    }

    if (baseInfo?.countryId) {
      if (statesCount === 0) getCitiesData(baseInfo?.countryId, null);
      else {
        getStatusData(baseInfo?.countryId);
        getCitiesData(null, baseInfo?.countryId);
      }
    }
    setSelectJobTitles(jobTitles);
    setSelectSkillTags(tags);
    getWorkPreferences();
    getJobDropDownData();

    setMaskedPlaceHolder(maskedPlaceHolderP);
    setMaskedPhoneNumber(maskedPhoneNumberP);

    formik.setValues({
      firstName: baseInfo?.first_name || "",
      lastName: baseInfo?.last_name || "",
      countryId: baseInfo?.countryId || null,
      country: baseInfo?.country || "",
      cityId: baseInfo?.cityId || null,
      city: baseInfo?.city || "",
      stateId: baseInfo?.stateId || null,
      state: baseInfo?.state || "",
      email: baseInfo?.email || "",
      phoneNo: baseInfo?.phone || "",
      linkedinURL: baseInfo?.linkedin || "",
      aboutSelf: baseInfo?.bio || "",
      interested: baseInfo?.my_interests || "",
      salaryExpectation: baseInfo?.expected_salary || 0,
      userWorkPreferences: baseInfo?.workPreferences,
      currency: baseInfo?.currency || "",
      currencyId: baseInfo?.currencyId || null,
      resume: baseInfo?.file,
      salaryRange: baseInfo?.salary_range,
    });
  }, []);

  // console.log("formik errors =======>", formik.errors);

  const logo = companyLogo
  ? URL.createObjectURL(companyLogo)
  : baseInfo?.avatar;

  const deleteImage = () => {
    deleteImageLogo()    
  }

  return (
    <div className="my-profile-row">
      <div className="candidtae-profile-image d-md-block d-none">
        <div className="new-profile-image-st profile-pic-desktop">
          <img
            src={
              companyLogo !== null
                ? URL?.createObjectURL(companyLogo)
                : baseInfo?.avatar === null || baseInfo?.avatar === undefined
                ? "/image/avtar.jpg"
                : baseInfo?.avatar
            }
            alt=""
            className="profile-opac"
          />
          <div className="addProfile-icon icon-cursor">
            <img src="/image/addProfile.png" alt="" onClick={handleImageChange} />
          </div>
        </div>
        {logo && (
           <div className="mt-3 text-center">
           <p className="cursor-pointer" onClick={deleteImage} style={{ fontSize: '13px', color: '#009FB5' }}>
             Remove Image
           </p>
         </div>
        )}
      </div>
      <span className="profile-img-mbl">
       <div className="candidtae-profile-image">
        <div className="new-profile-image-st">
            <img
              src={
                companyLogo !== null
                  ? URL?.createObjectURL(companyLogo)
                  : baseInfo?.avatar === null || baseInfo?.avatar === undefined
                  ? "/image/avtar.jpg"
                  : baseInfo?.avatar
              }
              alt=""
              className="profile-opac"
            />
            <div className="addProfile-icon icon-cursor">
              <img
                src="/image/addProfile.png"
                alt=""
                onClick={handleImageChange}
              />
            </div>
          </div>
          {logo && (
            <div className="mt-3 text-center">
              <p className="cursor-pointer" onClick={deleteImage} style={{ fontSize: '13px', color: '#009FB5' }}>
                Remove Image
              </p>
            </div>
          )}
       </div>
      </span>
      <div className="candidate-information with-accordion">
        <div className="candidate-about-me">
          <div className="candidate-profile-form">
            <h5 className="ms-4">About Me</h5>
            <form onSubmit={formik.handleSubmit}  onKeyDown={handleKeyDown}>
              <div className="form-block-width">
                <div className={`candidate-name-field-profile profile-field-f`}>
                  <div
                    class=""
                    className={`first-name-profile ${
                      isFormFieldValid("firstName") && "error-input"
                    }`}
                  >
                    <label for='candidate-name-field' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-name-field"
                      placeholder="First name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div
                    className={`first-name-profile ${
                      isFormFieldValid("lastName") && "error-input"
                    }`}
                  >
                    <label for='candidate-lname-field' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                    <input
                      className="form-font-f"
                      type="text"
                      id="candidate-lname-field"
                      placeholder="Last name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {getFormErrorMessage("lastName")}
                  </div>
                </div>
                <div
                  className={`profile-field-f signup-passowrd-job location-profile-list disable-field ${
                    isFormFieldValid("country") && "error-input"
                  }`}
                >
                  <div className="industry-feild-frm bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-location.png"
                        className="location-icon"
                        alt=""
                      />
                    </div>
                    <div
                      id="dropdown-boxId-2"
                      className="company-form-dropdownBox-profile"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {" "}
                      {formik?.values?.country === ""
                        ? "location"
                        : formik?.values?.country}
                    </div>
                    <div className="icon-hide">
                      {isFormFieldValid("country") ? (
                        <img src="/image/warning.png" alt="" />
                      ) : (
                        <img src="/image/form-d-f.png" alt="" />
                      )}
                    </div>
                  </div>
                  {getFormErrorMessage("country")}
                </div>

                <div className="candidate-username-signup-form">
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("state") && "error-input") ||
                      (stateOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setStateDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f location-f-new">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setStateDDHide(!stateDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.state === ""
                                  ? "Province/State"
                                  : formik?.values?.state}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("state") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`admin-status-dropdown-profile ${
                              !stateDDHide && stateOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                            <label for='job-search-field-2' className="visibility-hidden opacity-0 position-absolute">switch</label>
                              <input
                                name="searchLocation"
                                value={stateSearch}
                                onChange={(e) => searchStateLocation(e)}
                                id="job-search-field-2"
                                className="location-search-field"
                                placeholder="Province/State"
                                autocomplete="off"
                              />
                            </div>

                            {stateOptions?.length > 0 &&
                              !filteredStateItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {stateOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          stateHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredStateItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredStateItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        stateHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("state")}
                    </OutsideClickHandler>
                  </div>
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${
                      (isFormFieldValid("city") && "error-input") ||
                      (citiesOptions?.length === 0 && "disable-field")
                    }`}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setCityDDHide(true);
                      }}
                    >
                      <div className="industry-feild-frm bg-pass-f">
                        <div className="password-icon-logo">
                          <img
                            src="/image/profile-location.png"
                            className="location-icon"
                            alt=""
                          />
                        </div>
                        <div className="profile-location-feild">
                          <div
                            id="dropdown-boxId2"
                            className="profile-form-dropdownBox1"
                            onClick={() => setCityDDHide(!cityDDHide)}
                          >
                            <div className="text-filter-profile">
                              <p className="text-filter-select">
                                {" "}
                                {formik?.values?.city === ""
                                  ? "City"
                                  : formik?.values?.city}
                              </p>

                              <div className="icon-hide-2">
                                {isFormFieldValid("city") ? (
                                  <img src="/image/warning.png" alt="" />
                                ) : (
                                  <img src="/image/form-d-f.png" alt="" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`admin-status-dropdown-profile ${
                              !cityDDHide && citiesOptions?.length > 0
                                ? "open"
                                : ""
                            }`}
                          >
                            <div className="searchbox-invite">
                            <label for='job-search-field-3' className="visibility-hidden opacity-0 position-absolute">switch</label>
                              <input
                                name="searchLocation"
                                value={citySearch}
                                onChange={(e) => searchCityLocation(e)}
                                id="job-search-field-3"
                                className="location-search-field"
                                placeholder="City"
                                autocomplete="off"
                              />
                            </div>

                            {citiesOptions?.length > 0 &&
                              !filteredCityItem.length && (
                                <>
                                  <ul className="dorpdown-list-location">
                                    {citiesOptions?.map((j, i) => (
                                      <li
                                        className="icon-cursor"
                                        onClick={() =>
                                          cityHandler(j?.id, j?.name)
                                        }
                                      >
                                        <img
                                          src="/image/location-j-place.png"
                                          alt=""
                                        />
                                        {j?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}

                            {filteredCityItem?.length > 0 && (
                              <>
                                <ul className="dorpdown-list-location">
                                  {filteredCityItem.map((j, i) => (
                                    <li
                                      className="icon-cursor"
                                      onClick={() =>
                                        cityHandler(j?.id, j?.name)
                                      }
                                    >
                                      <img
                                        src="/image/location-j-place.png"
                                        alt=""
                                      />
                                      {j?.name}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {getFormErrorMessage("city")}
                    </OutsideClickHandler>
                  </div>
                </div>

                {/* <div className="candidate-username-signup-form">
                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("state") && "error-input"
                      ||
                      stateOptions?.length === 0 && "disable-field"
                      }`}
                  >
                    <div className="industry-feild-frm bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-location.png"
                          className="location-icon"
                          alt=""
                        />
                      </div>
                      <div
                        id="dropdown-boxId-2"
                        className="company-form-dropdownBox-profile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        {formik?.values?.state === ""
                          ? "Province/State"
                          : formik?.values?.state}
                      </div>
                      <div className="icon-hide-2">
                        {isFormFieldValid("state") ? (
                          <img src="/image/warning.png" alt="" />
                        ) : (
                          <img src="/image/form-d-f.png" alt="" />
                        )}
                      </div>
                      {stateOptions?.length > 0 && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t 
                          }`}
                        >
                          <ul>
                            {stateOptions?.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => stateHandler(j?.id, j?.name)}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {getFormErrorMessage("state")}
                  </div>

                  <div
                    className={`profile-field-f signup-passowrd-job location-profile-list first-name ${isFormFieldValid("city") && "error-input"
                      ||
                      citiesOptions?.length === 0 && "disable-field"
                      }`}
                  >
                    <div className="industry-feild-frm bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-location.png"
                          className="location-icon"
                          alt=""
                        />
                      </div>
                      <div
                        id="dropdown-boxId-2"
                        className="company-form-dropdownBox-profile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        {formik?.values?.city === ""
                          ? "City"
                          : formik?.values?.city}
                      </div>
                      <div className="icon-hide-2">
                        {isFormFieldValid("city") ? (
                          <img src="/image/warning.png" alt="" />
                        ) : (
                          <img src="/image/form-d-f.png" alt="" />
                        )}
                      </div>
                      {citiesOptions?.length > 0 && (
                        <div
                          id="company-dropList-t"
                          className={`dropdown-form-f company-drop-list-t`}
                        >
                          <ul>
                            {citiesOptions.map((j, i) => (
                              <li
                                className="icon-cursor-f"
                                key={i}
                                onClick={() => cityHandler(j?.id, j?.name)}
                              >
                                <img
                                  src="/image/profile-location.png"
                                  className="location-icon-drop"
                                  alt=""
                                />{" "}
                                {j?.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {getFormErrorMessage("city")}
                  </div>

                </div> */}
                <div className={`email-candidate-profile-f profile-field-f`}>
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img src="/image/profile-email.png" alt="" />
                    </div>
                    <label for='profile-email-candidate' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                    <input
                      className="form-font-f"
                      id="profile-email-candidate"
                      placeholder="E-mail"
                      name="email"
                      value={formik.values.email}
                    />
                    <div className="icon-hide">
                      <img src="/image/locked-field.png" alt="" />
                      <span className="tooltiptext-profile">
                        Contact Admin Support
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={`profile-field-f ${
                    isFormFieldValid("phoneNo") && "error-input"
                  }`}
                >
                  <div
                    className={`country-code-profile phone-f-profile  ${
                      isFormFieldValid("phoneNo") && "error-input"
                    }`}
                  >
                    <div className="location-f-icon bg-pass-f">
                      <div className="password-icon-logo">
                        <img
                          src="/image/profile-phone.png"
                          alt=""
                          className="profile-icon"
                        />
                      </div>
                      <label for='profile-call' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                      <PhoneNumber
                        className="form-font-f"
                        id="profile-call"
                        name="phoneNo"
                        value={formik.values.phoneNo}
                        maskedPlaceHolder={maskedPlaceHolder}
                        maskedPhoneNumber={maskedPhoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="icon-hide">
                        {isFormFieldValid("phoneNo") && (
                          <img src="/image/warning.png" alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("phoneNo")}
                </div>
                <div className={`candidate-link-profile profile-field-f 
                 ${isFormFieldValid("linkedinURL") && "error-input"}`}
                >
                  <div className="location-f-icon bg-pass-f">
                    <div className="password-icon-logo">
                      <img
                        src="/image/profile-linked.png"
                        alt=""
                        className="profile-icon"
                      />
                    </div>
                    <label for='social-link' className="visibility-hidden opacity-0 position-absolute">candidate-name-field</label>
                    <input
                      className="form-font-f"
                      type="text"
                      id="social-link"
                      placeholder="Enter your linkedIn profile here"
                      name="linkedinURL"
                      value={formik.values.linkedinURL}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                     {isFormFieldValid("linkedinURL") && (
                      <img src="/image/warning.png" alt="" />
                    )}
                  </div>
                  {getFormErrorMessage("linkedinURL")}
                </div>
              </div>
              <div className="form-block-width">
                <div className="row m-0">
                  <div className="col-md-6 order-md-0 order-1">
                    <button
                      type="button"
                      className="btn btn-black w-100 btn-lg"
                      onClick={(e) => {
                        e.preventDefault();
                        editHandler(false)
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 order-md-1 order-0">
                    <button
                      type="submit"
                      className="btn btn-outline-black w-100 btn-lg mb-md-0 mb-3"
                    >
                      {loader ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanProfileForm;
