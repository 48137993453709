import ReactHtmlParser from "react-html-parser";
import CanResumeSessionText from "../CanResumeSessionText/CanResumeSessionText";
import {
  candidateSummaryRegex,
  RESUME_SECTION_IDS,
  USER_TYPE,
} from "../../../../constants";
import "./CanResumeTempEXE.css";

const CanResumeTempEXE = ({
  enableAltText,
  userType,
  originalData,
  selectedSection,
  contactDetails,
  proExperience,
  education,
  certifications,
  keySkills,
  awardRecogn,
}) => {
  const sectionResumeSessionComponents = {
    [RESUME_SECTION_IDS.SUMMARY]: (section) => (
      <>
        {contactDetails?.candidate_summary &&
        contactDetails?.candidate_summary
          .replace(candidateSummaryRegex, "")
          .trim() !== "" ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats exe-heading">
              {" "}
              {section?.name || "Summary"}
            </div>
            <div className="resume-exe-bdr"></div>
            <div className="resume-job-list-exe">
              {ReactHtmlParser(contactDetails?.candidate_summary)}
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats exe-heading">
                {section?.name || "Summary"}
              </div>
              <div className="resume-exe-bdr"></div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Summary"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE]: (section) => (
      <>
        {proExperience?.length > 0 ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats exe-heading">
              {section?.name || "Professional Experience"}
            </div>
            <div className="resume-exe-bdr"></div>

            {
              proExperience
                .map((prod, index) => {
                  const jobTitle = prod?.job_title?.trim();
                  const companyName = prod?.company_name?.trim();
                  const city = prod?.city?.trim();
                  const country = prod?.country?.trim();
                  const location = [city, country].filter(Boolean).join(", ");

                  // Format work duration correctly
                  const startDate =
                    prod?.start_date?.month && prod?.start_date?.year
                      ? `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`
                      : "";
                  const endDate = prod?.is_present
                    ? "Present"
                    : prod?.end_date?.month && prod?.end_date?.year
                    ? `${prod?.end_date?.month?.substring(0, 3)} ${
                        prod?.end_date?.year
                      }`
                    : "";
                  const workDuration =
                    startDate || endDate ? `${startDate} - ${endDate}` : "";

                  const responsibilities =
                    prod?.roles_n_responsibilities?.trim();

                  // Skip rendering if job title, location, and work duration are all empty
                  if (
                    !jobTitle &&
                    !location &&
                    !workDuration &&
                    !responsibilities
                  )
                    return null;

                  return (
                    <div key={index}>
                      {/* Position */}
                      {jobTitle && (
                        <div className="res-postion-ats exe-sub-heading">
                          <span>Position: {jobTitle}</span>
                        </div>
                      )}

                      {/* Company Name, City, Country, Work Duration */}
                      {(companyName || location || workDuration) && (
                        <div className="time-comp-res-exe">
                          {companyName && (
                            <div className="sub-heading-res-ats">
                              {`${companyName},`}&nbsp;
                            </div>
                          )}

                          {location && (
                            <div className="sub-heading-res-right-exe">
                              {location}
                            </div>
                          )}

                          {/* Show | only if both location & workDuration exist */}
                          {location && workDuration && (
                            <div className="exe-right-pipe">{"|"}</div>
                          )}

                          {workDuration && (
                            <div className="sub-heading-res-right-ats exe-right-weight">
                              {workDuration}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Responsibilities */}
                      {responsibilities && (
                        <div className="resume-job-list-exe">
                          {ReactHtmlParser(responsibilities)}
                        </div>
                      )}
                    </div>
                  );
                })
                .filter(Boolean) /* Removes empty entries */
            }
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats exe-heading">
                {section?.name || "Professional Experience"}
              </div>
              <div className="resume-exe-bdr"></div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Professional Experience"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),

    [RESUME_SECTION_IDS.EDUCATION || RESUME_SECTION_IDS.CERTIFICATIONS]: () => (
      <>
        {education?.length > 0 || certifications?.length > 0 ? (
          <div className="resume-summar-exe">
            {/* Section Heading */}
            <div className="first-heading-res-ats exe-heading">
              Education & Certifications
            </div>
            <div className="resume-exe-bdr"></div>

            {/* Education Section */}
            {
              education?.length > 0 &&
                education
                  .map((prod, index) => {
                    const degree = prod?.degree?.trim();
                    const institute = prod?.institute?.trim();

                    // Format dates correctly
                    const startDate =
                      prod?.start_date?.month && prod?.start_date?.year
                        ? `${prod?.start_date?.month?.substring(0, 3)} ${
                            prod?.start_date?.year
                          }`
                        : "";
                    const endDate = prod?.is_present
                      ? "Present"
                      : prod?.end_date?.month && prod?.end_date?.year
                      ? `${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`
                      : "";
                    const workDuration =
                      startDate || endDate ? `${startDate} - ${endDate}` : "";

                    // Skip rendering if degree, institute, and workDuration are all empty
                    if (!degree && !institute && !workDuration) return null;

                    return (
                      <div className="education-res-exe" key={index}>
                        {/* Degree + Date */}
                        {(degree || workDuration) && (
                          <div className="d-flex resume-edu-exe">
                            {degree && <span>{degree}</span>}

                            {/* Show | only if both degree and work duration exist */}
                            {degree && workDuration && (
                              <div className="exe-right-pipe">{"|"}</div>
                            )}

                            {workDuration && (
                              <div className="sub-heading-res-right-ats exe-right-weight">
                                {workDuration}
                              </div>
                            )}
                          </div>
                        )}

                        {/* Institute */}
                        {institute && (
                          <div className="edu-uni-exe">
                            <div className="uni-res-exe">{institute}</div>
                          </div>
                        )}
                      </div>
                    );
                  })
                  .filter(Boolean) /* Removes empty entries */
            }

            {/* Certification Section */}
            {certifications?.length > 0 && (
              <>
                <div className="res-postion-ats exe-sub-heading">
                  Certifications:
                </div>
                <div className="resume-job-list-exe">
                  <ul>
                    {
                      certifications
                        ?.map((prod, index) => {
                          const certName = prod?.name?.trim();
                          const startDate =
                            prod?.start_date?.month && prod?.start_date?.year
                              ? `${prod?.start_date?.month?.substring(0, 3)} ${
                                  prod?.start_date?.year
                                }`
                              : "";
                          const endDate = prod?.is_present
                            ? "Present"
                            : prod?.end_date?.month && prod?.end_date?.year
                            ? `${prod?.end_date?.month?.substring(0, 3)} ${
                                prod?.end_date?.year
                              }`
                            : "";
                          const workDuration =
                            startDate || endDate
                              ? `${startDate} - ${endDate}`
                              : "";

                          // Only render <li> if there's valid data (name or date)
                          if (!certName && !workDuration) return null;

                          return (
                            <li key={index}>
                              <div className="d-flex">
                                {certName && <span>{certName}</span>}

                                {/* Show | only if both name and duration exist */}
                                {certName && workDuration && (
                                  <div className="exe-right-pipe">{"|"}</div>
                                )}

                                {workDuration && (
                                  <div className="sub-heading-res-right-ats exe-right-weight">
                                    {workDuration}
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        })
                        .filter(Boolean) /* Remove empty <li> */
                    }
                  </ul>
                </div>
              </>
            )}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats exe-heading">
                Education & Certifications
              </div>
              <div className="resume-exe-bdr"></div>
              <CanResumeSessionText context="Select the edit button to add Education & Certifications." />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.SKILLS]: (section) => (
      <>
        {keySkills?.length > 0 ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats exe-heading">
              {" "}
              {section?.name || "Skills"}
            </div>
            <div className="resume-exe-bdr"></div>
            <div className="resume-job-list-exe">
              <ul>
                {
                  keySkills
                    ?.map((skill, index) => {
                      const skillName = skill?.name?.trim();
                      const skillTags =
                        skill?.tags?.filter((tag) => tag?.trim()) || [];

                      // Ensure at least skill name or skill tags exist before rendering <li>
                      if (!skillName && skillTags?.length === 0) return null;

                      return (
                        <li key={index}>
                          {skillName ? `${skillName}: ` : ""}
                          {skillTags?.length > 0 ? skillTags?.join(", ") : ""}
                        </li>
                      );
                    })
                    .filter(Boolean) // Removes null values to avoid rendering empty <li>
                }
              </ul>
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats exe-heading">
                {" "}
                {section?.name || "Skills"}
              </div>
              <div className="resume-exe-bdr"></div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Skills"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.AWARDS_RECOGNITION]: (section) => (
      <>
        {awardRecogn &&
        awardRecogn?.replace(candidateSummaryRegex, "").trim() !== "" ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats exe-heading">
              {section?.name || "Awards & Recognition "}
            </div>
            <div className="resume-exe-bdr"></div>
            <div className="resume-job-list-exe">
              {ReactHtmlParser(awardRecogn)}
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats exe-heading">
                {section?.name || "Awards & Recognition "}
              </div>
              <div className="resume-exe-bdr"></div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Awards & Recognition"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
  };

  return (
    <>
      <section className="resume-choose-template">
        <div className="container container-site">
          <div
            className={`resume-verivication-res ${
              userType === USER_TYPE.CANDIDATE &&
              "resume-verivication-res-border "
            }`}
          >
            <div className="resume-start-verivication">
              <div className="all-content-resume-verification">
                <div className="name-res-ats">
                  {`${contactDetails?.first_name ?? ""} ${
                    contactDetails?.last_name ?? ""
                  }`}
                </div>

                <div className="resume-info-exe">
                  <div className="res-mail-exe">
                    {contactDetails?.address?.trim() && (
                      <a>{contactDetails?.address}</a>
                    )}
                  </div>
                </div>

                <div className="resume-info-exe">
                  {contactDetails?.phone_number?.trim() && (
                    <div className="res-exe-phone">
                      <a href="#">{contactDetails?.phone_number}</a>
                    </div>
                  )}

                  <div className="res-mail-exe">
                    <ul>
                      {contactDetails?.email?.trim() && (
                        <li>
                          <a href="#">{contactDetails?.email}</a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="resume-info-exe">
                  <div className="res-mail-exe">
                    {contactDetails?.linkedin_url?.trim() && (
                      <a>{contactDetails?.linkedin_url}</a>
                    )}
                  </div>
                </div>
                {/* Dynamic Resume Preview Sections */}

                {selectedSection
                  ?.sort((a, b) => a?.pivot?.order - b?.pivot?.order)
                  ?.map((section) =>
                    sectionResumeSessionComponents[section?.id]
                      ? sectionResumeSessionComponents[section?.id](section)
                      : null
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CanResumeTempEXE;
