import ReactHtmlParser from "react-html-parser";
import CanResumeSessionText from "../CanResumeSessionText/CanResumeSessionText";
import {
  candidateSummaryRegex,
  RESUME_SECTION_IDS,
  USER_TYPE,
} from "../../../../constants";
import "./CanResumeTempATS.css";

const CanResumeTempATS = ({
  enableAltText,
  userType,
  originalData,
  selectedSection,
  contactDetails,
  keyCompetencies,
  proExperience,
  projects,
  education,
  certificates,
  certifications,
  keySkills,
  techSkills,
}) => {
  const sectionResumeSessionComponents = {
    [RESUME_SECTION_IDS.SUMMARY]: (section) => (
      <>
        {contactDetails?.candidate_summary &&
        contactDetails?.candidate_summary
          ?.replace(candidateSummaryRegex, "")
          ?.trim() !== "" ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Summary"}
            </div>
            <div className="res-sumry-ats">
              {ReactHtmlParser(contactDetails?.candidate_summary)}
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {" "}
                {section?.name || "Summary"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Summary"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.KEY_COMPETENCIES]: (section) => (
      <>
        {keyCompetencies?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Key Competencies"}
            </div>
            <div className="keys-list-ats">
              {keyCompetencies?.map((prod, index) => (
                <div className="keys-list-content-ats">
                  {prod?.name !== "" && `${prod?.name}`}
                </div>
              ))}
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {" "}
                {section?.name || "Key Competencies"}s
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Key Competencies"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE]: (section) => (
      <>
        {proExperience?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Professional Experience"}
            </div>
            {proExperience?.map((prod, index) => (
              <>
                <div className="time-comp-res-ats">
                  <div className="sub-heading-res-ats">
                    {prod?.company_name !== "" ? prod?.company_name : ""}
                    {prod?.city !== "" ? `, ${prod?.city}` : ""}
                    {prod?.country !== "" ? `, ${prod?.country}` : ""}
                  </div>
                  <div className="sub-heading-res-right-ats">
                    {prod?.start_date?.month === "" &&
                    prod?.start_date?.year === ""
                      ? ""
                      : `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`}{" "}
                    {prod?.is_present
                      ? "- Present"
                      : prod?.end_date?.month === "" &&
                        prod?.end_date?.year === ""
                      ? ""
                      : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`}
                  </div>
                </div>
                <div className="res-postion-ats">
                  <span>
                    {" "}
                    {prod?.job_title !== ""
                      ? `Position: ${prod?.job_title}`
                      : ""}{" "}
                  </span>{" "}
                </div>
                <div className="resume-job-list-ats">
                  {prod?.roles_n_responsibilities !== "" &&
                    ReactHtmlParser(prod?.roles_n_responsibilities)}
                </div>
              </>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {section?.name || "Professional Experience"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Professional Experience"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.PROJECTS]: (section) => (
      <>
        {projects?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Projects"}
            </div>
            {projects?.map((prod, index) => (
              <>
                <div className="time-comp-res-ats">
                  <div className="sub-heading-res-ats">
                    {prod?.name !== "" ? prod?.name : ""}
                  </div>
                </div>
                <div className="project-comp-res-ats">
                  {prod?.url !== "" && (
                    <p className="res-postion-ats">
                      Project URL:{" "}
                      <a
                        target="_blank"
                        href={prod?.url !== "" ? prod?.url : "#"}
                        className="project-link-res-ats"
                      >
                        {prod?.url !== "" ? prod?.url : ""}
                      </a>{" "}
                    </p>
                  )}
                </div>
                {prod?.description && prod?.description !== "" && (
                  <div className="resume-job-list-ats">
                    <ul>
                      <li>{prod?.description} </li>
                    </ul>
                  </div>
                )}
              </>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {" "}
                {section?.name || "Projects"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Projects"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.EDUCATION]: (section) => (
      <>
        {education?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Education"}
            </div>
            {education?.map((prod, index) => (
              <>
                <div className="time-comp-res-ats">
                  <div className="uni-heading-res-ats">
                    {prod?.institute !== "" ? prod?.institute : ""}
                  </div>
                  <div className="sub-heading-res-right-ats">
                    {prod?.start_date?.month === "" &&
                    prod?.start_date?.year === ""
                      ? ""
                      : `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`}{" "}
                    {prod?.is_present
                      ? "- Present"
                      : prod?.end_date?.month === "" &&
                        prod?.end_date?.year === ""
                      ? ""
                      : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`}
                  </div>
                </div>
                <p className="edu-resume-ats">
                  {" "}
                  {prod?.degree !== "" ? prod?.degree : ""}
                </p>
              </>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {" "}
                {section?.name || "Education"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Education"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.CERTIFICATIONS]: (section) => (
      <>
        {certifications?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Certifications"}{" "}
            </div>
            {certifications?.map((prod, index) => (
              <>
                <div className="certification-res-ats">
                  <div className="resume-certificate-ats">
                    {prod?.name !== "" ? prod?.name : ""}
                  </div>
                  <div className="sub-heading-res-right-ats">
                    {prod?.start_date?.month === "" &&
                    prod?.start_date?.year === ""
                      ? ""
                      : `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`}{" "}
                    {prod?.end_date?.month === "" && prod?.end_date?.year === ""
                      ? ""
                      : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`}
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {" "}
                {section?.name || "Certifications"}{" "}
              </div>

              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Certifications"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.SKILLS]: (section) => (
      <>
        {keySkills?.length > 0 ? (
          <div className="resume-summar-ats">
            <div className="first-heading-res-ats">
              {" "}
              {section?.name || "Skills"}{" "}
            </div>
            <ul className="resume-job-list-skill">
              {
                keySkills
                  ?.map((skill, index) => {
                    const skillName = skill?.name?.trim();
                    const skillTags =
                      skill?.tags?.filter((tag) => tag?.trim()) || [];

                    // Ensure at least skill name or skill tags exist before rendering <li>
                    if (!skillName && skillTags?.length === 0) return null;

                    return (
                      <li key={index}>
                        {skillName ? skillName : ""}
                        {skillTags?.length > 0
                          ? `: ${skillTags?.join(", ")}`
                          : ""}
                      </li>
                    );
                  })
                  .filter(Boolean) // Removes null values to avoid rendering empty <li>
              }
            </ul>

            {/* <div className="resume-job-list-ats">
              <ul>
                {techSkills
                  .filter((skill) => skill?.name?.trim()) // Remove empty skills
                  .map((prod, index) => (
                    <li key={index}>{prod?.name}</li>
                  ))}
              </ul>
            </div> */}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-ats">
              <div className="first-heading-res-ats">
                {section?.name || "Skills"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Skills"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
  };

  return (
    <>
      <section className="resume-choose-template">
        <div className="container container-site">
          <div
            className={`resume-verivication-res ${
              userType === USER_TYPE.CANDIDATE &&
              "resume-verivication-res-border "
            }`}
          >
            <div className="resume-start-verivication">
              <div className="all-content-resume-verification">
                <div className="name-res-ats">
                  {`${contactDetails?.first_name ?? ""} ${
                    contactDetails?.last_name ?? ""
                  }`}
                </div>
                <div className="resume-info-ats">
                  {contactDetails?.phone_number !== "" && (
                    <div className="res-ats-phone">
                      <a href="#">{contactDetails?.phone_number}</a>
                    </div>
                  )}
                  {contactDetails?.email !== "" && (
                    <div className="res-mail-ats">
                      <ul>
                        <li>
                          <a href="#">{contactDetails?.email}</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className="location-res-ats">
                  {contactDetails?.address !== null &&
                    contactDetails?.address !== "" && (
                      <p>{contactDetails?.address}</p>
                    )}
                </div>

                {/* Dynamic Resume Preview Sections */}

                {selectedSection
                  ?.sort((a, b) => a?.pivot?.order - b?.pivot?.order)
                  ?.map((section) =>
                    sectionResumeSessionComponents[section?.id]
                      ? sectionResumeSessionComponents[section?.id](section)
                      : null
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CanResumeTempATS;
