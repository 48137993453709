export const PublicDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "icloud.com",
  "aol.com",
  "protonmail.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "mail.ru",
  "gmx.com",
  "inbox.com",
  "qq.com",
  "163.com",
  "126.com",
  "gmx.net",
  "rediffmail.com",
  "web.de",
  "naver.com",
  "daum.net",
  // Add more public domains here
];

export const USER_TYPES = Object.freeze({
  CANDIDATE: "candidate",
  EMPLOYER: "employer",
  // add more userTypes here
});

export const IMPRESSION_TYPES = Object.freeze({
  NOT_A_GOOD_FIT: 1,
  NEED_TO_THINK: 2,
  GOOD_CANDIDATE: 3,
});

export const CANDIDATE_STATUSES = Object.freeze({
  NEW_APPLICANT: 1,
  RESUME_REVIEWED: 2,
  DO_NOT_CONTACT: 3,
  OVERQUALIFIED_FOR_THIS_POSITION: 4,
  EXPERIENCE_NOT_RELEVANT: 5,
  NEED_TO_THINK_ABOUT_HIM: 6,
  NO_EXPERIENCE_AT_ALL: 7,
});

export const EmptyRecord = ["", null, "Unknown", NaN, undefined, "null"];

export const CANDIDATE_Detail_Tab = [
  { name: "Resume", value: "resume" },
  { name: "Candidate profile", value: "candidate-activities" },
  { name: "AI candidate evaluation", value: "candidate-evaluation" },
  { name: "Notes", value: "notes" },
  // { name: "Team messages", value: "messages-tab" },
];

export const BULK_RESUME_CAN_Detail_Tab = [
  { name: "Resume", value: "resume" },
  { name: "AI candidate evaluation", value: "candidate-evaluation" },
];

export const JOB_POST_DECISION = Object.freeze({
  SYSTEM_GEN: "SYSTEM_GEN",
  COPY_PASTE: "COPY_PASTE",
  ZOE: "ZOE",
});

export const JOB_GEN_METHOD = Object.freeze({
  SYSTEM_DESC: "system_desc",
  SELF_DESC: "self_desc",
  ZOE_DESC: "zoe_desc",
});

export const JOB_POST_FIELDS_AI = Object.freeze({
  JOB_SUMMARY: "job_summary",
  ROLES_AND_RESPONSIBILITIES: "roles_n_responsibilities",
  SKILLS: "skills",
  MUST_HAVE: "must_have_skills",
  SKILL_TAGS: "key_skill_tags",
  NICE_TO_HAVE: "nice_to_have_skills",
  BENEFITS: "benefits",
  WORKING_HOURS: "working_hours",
  INTERVIEW_PROCESS: "interview_process",
  RECRUITER_NOTES: "recruiter_notes",
});

export const USER_TYPE = Object.freeze({
  CANDIDATE: "candidate",
  EMPLOYER: "employer",
  COMPANY_USER: "company_user",
  SUPER_ADMIN: "superadmin",
});

export const CANDIDATE = ["candidate"];

export const EMPLOYER = ["employer", "company_user"];

export const ADMIN = ["employer", "company_user", "superadmin"];

export const AllUsers = ["employer", "company_user", "superadmin", "candidate"];

export const RESUME_MODE = Object.freeze({
  UPLOAD: "upload",
  VERIFICATION: "Verification",
  UPDATE: "Update",
  CUSTOMIZED: "Customized",
  CREATE: "Create",
  CHOOSE_TEMPLATES:"Choose templates"
});

export const RESUME_FORM_TAB = Object.freeze({
  EDIT: "edit",
  PREVIEW: "preview"
});

export const RESUME_SECTION_IDS = {
  SUMMARY: 1,
  KEY_COMPETENCIES: 2,
  PROFESSIONAL_EXPERIENCE: 3,
  PROJECTS: 5,
  EDUCATION: 6,
  CERTIFICATES: 7,
  CERTIFICATIONS: 8,
  SKILLS: 9,
  TECHNICAL_SKILLS: 10,
  ACTIVITIES: 12,
  AWARDS_RECOGNITION: 13,
  CERTIFICATES_ACTIVITIES_SKILLS: 14,
};

export const RESUME_TEMPLATE_IDS = {
  DEFAULT: 1,
  STUDENT: 2,
  CLASSIC_CORPORATE: 3,
  EXECUTIVE: 4,
};


export const PARSING_STATUS = Object.freeze({
  GOODFIT: "good fit",
  COMPLETED: "completed",
  COMPLETE: "complete",
  SUCCESS: "success",
  PROCESS: "in-process",
  PROCESSING: "processing",
  PENDING: "pending",
  LOWFIT: "low fit",
  FAIL: "fail",
  FAILED: "failed",
  NOT_ANALYZED: "not analysed",
  NOT_READABLE: "not readable",
});

export const RESUME_ITEM = Object.freeze({
  PROFESSIONAL_EXPERIENCE: "professional experience",
  PROJECT: "project",
  EDUCATION: "education",
  CERTIFICATES: "certificates",
  CERTIFICATION: "certification",
  SKILLS: "skills",
  TECHNICAL_SKILLS: "technical skills"
});

export const BULK_RESUME_ANALYSIS_SORT = Object.freeze({
  ID: "id",
  PROCESS_ID: "process_id",
  JOB_TITLE: "job_title",
  TOTAL_RESUMES: "total_resumes",
  RESUME_PARSED: "resume_parsed",
  RESUME_IN_ERROR: "resume_in_error",
  DATE_SUBMITTED: "date_submitted",
  ZIP_NAME: "zip_name",
});

export const BULK_REQ_DETAIL_SORT = Object.freeze({
  ID: "id",
  RESUME_FILENAME: "resume_filename",
  CANDIDATE_NAME: "candidate_name",
  OVERALL_MATCH: "overall_match",
  PROCESS_STATUS: "process_status",
  DATE_PROCESSED: "date_processed",
});

export const BULK_ANALYSIS_SORT = Object.freeze({
  ID: "id",
  FIRST_NAME: "first_name",
  OVERALL_EXPERIENCE: "overall_experience",
  OVERALL_MATCH: "overall_match",
  DATE_APPLIED: "date_applied",
});

export const SORT_DIRECTION = Object.freeze({
  ASC: "asc",
  DESC: "desc",
});

export const CONVERSATION_STATUS = Object.freeze({
  RECENT: "recent",
  UNREAD: "unread",
  PINNED: "pinned",
});

export const BUY_BULK_SORT = Object.freeze({
  CREATED_AT: "created_at",
});

export const CAN_JOURNEY_STATUS = Object.freeze({
  COMPLETED: "completed",
  PROGRESS: "in-progress",
});

export const IS_IMAGE = ["image/jpeg", "image/png", "image/jpg"];

export const IS_PDF = ["application/pdf"];

export const IS_Doc = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const POST_JOB_HIDE = ["/signup", "/employer-candidate-detail"];

export const INBOX_ROUTER = ["/employer-inbox", "/candidate-inbox"];

export const POST_JOB_TITLE = [
  "manager",
  "director",
  "vice president",
  "vp",
  "ceo",
  "cto",
  "cfo",
  "chief technology officer",
  "chief executive officer",
  "lead",
  "team lead",
];

export const EMP_CAND_LIST = Object.freeze({
  ALL: "ALL",
  MY_LIST: "MY_LIST",
  SHARE_LIST: "SHARE_LIST",
});

export const EMP_LIST = Object.freeze({
  CREATED_BY_ME: "CREATED_BY_ME",
  SHARED_BY_ME: "SHARED_BY_ME",
  SHARED_WITH_ME: "SHARED_WITH_ME",
});

export const COUNTRY_ISO_CODE = Object.freeze({
  MK: "North Macedonia",
  CA: "Canada",
});

export const DOMAIN_MK = [
  "demo.polycareers.com.mk",
  "dev.polycareers.com.mk",
  "qa.polycareers.com.mk",
  "staging.polycareers.com.mk",
  "polycareers.com.mk",
  "prod.polycareers.com.mk",
  "polycareers.com.mk",
];

export const DOMAIN_CA = [
  "demo.polycareers.com",
  "dev.polycareers.com",
  "qa.polycareers.com",
  "staging.polycareers.com",
  "polycareers.com",
  "prod.polycareers.com",
  "polycareers.com",
];

export const SHOW_ROW_COUNT = ["10", "20", "30", "40", "50"];

export const PERMISSION_ROLES_DROPDOWN = ["Add all", "Remove all"];

export const UNAUTHENTICATED = "unauthenticated.";

export const timeFrames = [
  {
    id: 4,
    in_weeks: 4,
    name: "4 weeks",
  },
  {
    id: 1,
    in_weeks: 1,
    name: "1 week",
  }
];

export const promotionTypes = [
  {
    id: 1,
    name: "Promote my company",
    slug: "top-companies",
    price: 75,
    country: {
      id: 2,
      name: "Canada",
      nationality: null,
      flag_link: null,
      order: 0,
      is_default: 0,
      status: "published",
      created_at: "2024-04-18T17:49:27.000000Z",
      updated_at: "2024-04-18T17:49:27.000000Z",
      code: null,
      masked_phone_number: "+1 (999) 999-9999",
      placeholder: "+1 ___-___-____",
      locale: "en_US",
      iso_country_code: "CA",
      currency: {
        id: 2,
        title: "$",
        name: "Canidian Doller",
        symbol: "cad$",
        flag_link: null,
        is_prefix_symbol: 0,
        decimals: 0,
        order: 0,
        is_default: 0,
        exchange_rate: 1,
        created_at: null,
        updated_at: null,
        country_id: 2,
      },
    },
  },
  {
    id: 2,
    name: "Promote my job",
    slug: "job-ads",
    price: 50,
    country: {
      id: 2,
      name: "Canada",
      nationality: null,
      flag_link: null,
      order: 0,
      is_default: 0,
      status: "published",
      created_at: "2024-04-18T17:49:27.000000Z",
      updated_at: "2024-04-18T17:49:27.000000Z",
      code: null,
      masked_phone_number: "+1 (999) 999-9999",
      placeholder: "+1 ___-___-____",
      locale: "en_US",
      iso_country_code: "CA",
      currency: {
        id: 2,
        title: "$",
        name: "Canidian Doller",
        symbol: "cad$",
        flag_link: null,
        is_prefix_symbol: 0,
        decimals: 0,
        order: 0,
        is_default: 0,
        exchange_rate: 1,
        created_at: null,
        updated_at: null,
        country_id: 2,
      },
    },
  },
];

export const ADMIN_SIDE_BAR = [
  {
    id: "2",
    name: "Team setup",
    value: "Team setup",
    path: "/admin-department",
    subMenuOpen: false,
    icon: "/image/system-nav.png",
    iconActive: "/image/system-nav-b.png",
    key: "department_view",
    subMenuOption: [
      {
        id: "23",
        name: "Department",
        value: "Department",
        path: "/admin-department",
        subMenuOpen: false,
        icon: "/image/deprt-nav.png",
        iconActive: "/image/deprt-nav-b.png",
        key: "department_view",
      },
      {
        id: "24",
        name: "Roles",
        value: "Roles",
        path: "/admin-role",
        subMenuOpen: false,
        icon: "/image/role-nav.png",
        iconActive: "/image/role-nav-b.png",
        key: "roles_view",
      },
      {
        id: "25",
        name: "Users",
        value: "Users",
        path: "/admin-user",
        subMenuOpen: false,
        icon: "/image/user-nav.png",
        iconActive: "/image/user-nav-b.png",
        key: "users_view",
      },
    ],
  },
  {
    id: "3",
    name: "Promotions",
    value: "Promotions",
    path: "/admin-all-promotion",
    subMenuOpen: false,
    icon: "/image/promotion-icon.png",
    iconActive: "/image/promotion-icon-b.png",
    key: "promotions_view",
    subMenuOption: [
      {
        id: "32",
        name: "All promotions",
        value: "All promotions",
        path: "/admin-all-promotion",
        subMenuOpen: false,
        icon: "/image/promotion-icon.png",
        iconActive: "/image/promotion-icon-b.png",
        key: "promotions_view",
      },
      {
        id: "34",
        name: "Promotions requests",
        value: "Promotions requests",
        path: "/admin-promotion-req",
        subMenuOpen: false,
        icon: "/image/promotion-icon.png",
        iconActive: "/image/promotion-icon-b.png",
        key: "promotions_view",
      },
    ],
  },
  {
    id: "41",
    name: "Bulk resume analysis",
    value: "Bulk analysis",
    path: "/bulk-resume-analysis",
    subMenuOpen: false,
    icon: "/image/bulk-resume-m.png",
    iconActive: "/image/bulk-resume-m.png",
    key: "bulk_resume_view",
    subMenuOption: [],
  },
];

/* Regex */
export const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
export const jobTitleRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s'.,\-\/()&#+]+$/;
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const addressRegex =
  /^(?!.*(<.*?>|\b(function|return|echo|let|const|var|php|script|console|eval|foreach|try|catch)\b|\{|\}|\[|\]|\(|\)|=>|::|;)).*$/i;
export const scriptOnlyHTMLTagRegex =
  /^(?!.*<script\b)(?!.*<\/script>)(?!.*<style\b)(?!.*<\/style>)(?!.*\b(eval|console|XMLHttpRequest|onload|onerror|onclick|onmouseover|onmouseout|onfocus|onblur|onsubmit|onreset|onchange|onkeydown|onkeyup|onkeypress|javascript:)\b)(?!.*\bstyle\s*=\s*['"][^'"]*['"]).*$/is;
export const numericWithCommaAndDecimal = /^[0-9,]+(\.[0-9]+)?$/;
export const urlRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
export const linkedInUrlRegex = /^(https:\/\/)?(www\.)?linkedin\.com\/.*$/;
export const websiteUrlRegex =
  /^(https?:\/\/)?www\.[a-zA-Z0-9\-]+\.(ca|com|net|org|tech|store|biz|shop|jobs|app|dev|io|ai|cloud|software|hosting|digital|blog|inc|site|online|web)(\/.*)?$/;
export const companyNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
export const depNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
export const roleNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
export const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const phoneNoRegex =  /^\+?[\d\s()-]{7,20}$/;

/* Candidate Regex */
export const candidateSummaryRegex = /(<([^>]+)>)/gi;
