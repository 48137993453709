import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { scriptOnlyHTMLTagRegex } from "../../../../constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      // { list: "unordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const allFormats = ["bold", "italic", "underline", "list", "bullet", "indent"];

const CanResumeAwardRecognForm = ({
  awardRecogn,
  onAwardRecognChangeHandler,
}) => {
  const formik = useFormik({
    initialValues: {
      awards_recognitions: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      awards_recognitions: Yup.string().matches(
        scriptOnlyHTMLTagRegex,
        "Please enter valid awards and recognitions"
      ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getExpData = async () => {
    await formik.setFieldValue(
      "awards_recognitions",
      typeof awardRecogn === "string" ? awardRecogn : ""
    );
  };

  useEffect(() => {
    getExpData();
  }, []);

  return (
    <>
      <div className="accordion-item experince-res-ver-right-skills">
        <button
          className="accordion-button resume-skills-right-tb"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sub-collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
            <div
              className={`address-res f-feild-res tags-tittles ${
                isFormFieldValid("awards_recognitions") && "error-input"
              }`}
            >
              <label for="lname">Awards & Recognition</label>
              <br />
              <ReactQuill
                theme="snow"
                className={`${isFormFieldValid(
                  "awards_recognitions"
                )} && "error-input"`}
                placeholder="Awards & Recognition"
                value={formik?.values?.awards_recognitions ?? ""}
                name="awards_recognitions"
                modules={modules}
                formats={allFormats}
                onChange={(data) => {
                  formik.setFieldValue("awards_recognitions", data);

                  onAwardRecognChangeHandler(data);
                }}
                onBlur={() => {
                  formik.handleBlur("awards_recognitions");
                }}
              />
              {getFormErrorMessage("awards_recognitions")}
            </div>
          </form>
        </button>
      </div>
    </>
  );
};

export default CanResumeAwardRecognForm;
