import ReactHtmlParser from "react-html-parser";
import CanResumeSessionText from "../CanResumeSessionText/CanResumeSessionText";
import {
  candidateSummaryRegex,
  RESUME_SECTION_IDS,
  USER_TYPE,
} from "../../../../constants";
import "./CanResumeTemp.css";

const CanResumeTemp = ({
  enableAltText,
  userType,
  originalData,
  selectedSection,
  contactDetails,
  proExperience,
  projects,
  education,
  certifications,
  keySkills,
}) => {
  const handlePreviewResume = (resumeURL) => {
    if (resumeURL !== null) {
      const pdfUrl = resumeURL;
      window.open(pdfUrl, "_blank");
    }
  };

  const sectionResumeSessionComponents = {
    [RESUME_SECTION_IDS.SUMMARY]: (section) => (
      <>
        {contactDetails?.candidate_summary &&
        contactDetails?.candidate_summary
          ?.replace(candidateSummaryRegex, "")
          ?.trim() !== "" ? (
          <>
            <div className="first-heading-res-left">
              {section?.name || "Summary"}
            </div>
            <p className="res-sumry-left">
              {ReactHtmlParser(contactDetails?.candidate_summary)}
            </p>
          </>
        ) : (
          enableAltText && (
            <>
              <div className="first-heading-res-left">
                {section?.name || "Summary"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Summary"
                }.`}
              />
            </>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE]: (section) => (
      <>
        {proExperience?.length > 0 ? (
          <>
            <div className="first-heading-res-left">
              {section?.name || "Professional Experience"}
            </div>
            {proExperience?.map((prod, index) => (
              <div key={index}>
                <div className="time-comp-res-left">
                  <div className="sub-heading-res-left">
                    {prod?.company_name !== "" ? prod?.company_name : ""}
                    {prod?.city !== "" ? `, ${prod?.city}` : ""}
                    {prod?.country !== "" ? `, ${prod?.country}` : ""}
                  </div>
                  <div className="sub-heading-res-right">
                    {prod?.start_date?.month === "" &&
                    prod?.start_date?.year === ""
                      ? ""
                      : `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`}{" "}
                    {prod?.is_present
                      ? "- Present"
                      : prod?.end_date?.month === "" &&
                        prod?.end_date?.year === ""
                      ? ""
                      : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`}
                  </div>
                </div>
                <p className="res-postion-left">
                  <span>
                    {prod?.job_title !== ""
                      ? `Position: ${prod?.job_title}`
                      : ""}
                  </span>{" "}
                </p>
                <div className="cus-resume-job-list-left">
                  {prod?.roles_n_responsibilities !== "" &&
                    ReactHtmlParser(prod?.roles_n_responsibilities)}
                </div>
              </div>
            ))}
          </>
        ) : (
          enableAltText && (
            <>
              <div className="first-heading-res-left">
                {section?.name || "Professional Experience"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Professional Experience"
                }.`}
              />
            </>
          )
        )}
      </>
    ),
    [RESUME_SECTION_IDS.PROJECTS]: (section) => (
      <>
        <div className="project-resu">
          {projects?.length > 0 ? (
            <>
              <div className="first-heading-res-left">
                {section?.name || "Projects"}
              </div>
              {projects?.map((prod, index) => (
                <>
                  <div className="time-comp-res-left">
                    <div className="sub-heading-res-left">
                      {prod?.name !== "" ? prod?.name : ""}
                    </div>
                  </div>
                  <div className="project-comp-res-left">
                    {prod?.url !== "" && (
                      <p className="res-postion-left">
                        Project URL:{" "}
                        <a
                          target="_blank"
                          href={prod?.url !== "" ? prod?.url : "#"}
                          className="project-link-res"
                        >
                          {prod?.url !== "" ? prod?.url : ""}
                        </a>{" "}
                      </p>
                    )}
                  </div>
                  {prod?.description && prod?.description !== "" && (
                    <div className="cus-resume-job-list-left">
                      <ul className="resume-job-list-left">
                        <li>{prod?.description} </li>
                      </ul>
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            enableAltText && (
              <>
                <div className="first-heading-res-left">
                  {" "}
                  {section?.name || "Projects"}
                </div>
                <CanResumeSessionText
                  context={`Select the edit button to add ${
                    section?.name || "Projects"
                  }.`}
                />
              </>
            )
          )}
        </div>
      </>
    ),
    [RESUME_SECTION_IDS.EDUCATION]: (section) => (
      <>
        <div className="education-resu">
          {education?.length > 0 ? (
            <>
              <div className="first-heading-res-left">
                {" "}
                {section?.name || "Education"}
              </div>
              {education?.map((prod, index) => (
                <>
                  <div className="time-comp-res-left">
                    <div className="sub-heading-res-left">
                      {prod?.institute !== "" ? prod?.institute : ""}
                    </div>
                    <div className="sub-heading-res-right">
                      {prod?.start_date?.month === "" &&
                      prod?.start_date?.year === ""
                        ? ""
                        : `${prod?.start_date?.month?.substring(0, 3)} ${
                            prod?.start_date?.year
                          }`}{" "}
                      {prod?.is_present
                        ? "- Present"
                        : prod?.end_date?.month === "" &&
                          prod?.end_date?.year === ""
                        ? ""
                        : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                            prod?.end_date?.year
                          }`}
                    </div>
                  </div>
                  <p className="edu-resume-left">
                    {prod?.degree !== "" ? prod?.degree : ""}
                  </p>
                </>
              ))}
            </>
          ) : (
            enableAltText && (
              <>
                <div className="first-heading-res-left">
                  {" "}
                  {section?.name || "Education"}
                </div>
                <CanResumeSessionText
                  context={`Select the edit button to add ${
                    section?.name || "Education"
                  }.`}
                />
              </>
            )
          )}
        </div>
      </>
    ),
    [RESUME_SECTION_IDS.CERTIFICATIONS]: (section) => (
      <>
        <div className="certificat-resume">
          {certifications?.length > 0 ? (
            <>
              <div className="first-heading-res-left">
                {" "}
                {section?.name || "Certifications"}
              </div>
              {certifications?.map((prod, index) => (
                <div className="certification-res-jour">
                  <ul className="certificate-res-list-left">
                    {prod?.name !== "" ? <li>{prod?.name}</li> : ""}
                  </ul>
                  <div className="sub-heading-res-right">
                    {prod?.start_date?.month === "" &&
                    prod?.start_date?.year === ""
                      ? ""
                      : `${prod?.start_date?.month?.substring(0, 3)} ${
                          prod?.start_date?.year
                        }`}{" "}
                    {prod?.end_date?.month === "" && prod?.end_date?.year === ""
                      ? ""
                      : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                          prod?.end_date?.year
                        }`}
                  </div>
                </div>
              ))}
            </>
          ) : (
            enableAltText && (
              <>
                <div className="first-heading-res-left">
                  {" "}
                  {section?.name || "Certifications"}
                </div>
                <CanResumeSessionText
                  context={`Select the edit button to add ${
                    section?.name || "Certifications"
                  }.`}
                />
              </>
            )
          )}
        </div>
      </>
    ),
    [RESUME_SECTION_IDS.SKILLS]: (section) => (
      <>
        {keySkills?.length > 0 ? (
          <>
            <div className="first-heading-res-left">
              {" "}
              {section?.name || "Skills"}
            </div>
            <ul className="resume-job-list-skill">
              {
                keySkills
                  ?.map((skill, index) => {
                    const skillName = skill?.name?.trim();
                    const skillTags =
                      skill?.tags?.filter((tag) => tag?.trim()) || [];

                    // Ensure at least skill name or skill tags exist before rendering <li>
                    if (!skillName && skillTags?.length === 0) return null;

                    return (
                      <li key={index}>
                        {skillName ? skillName : ""}
                        {skillTags?.length > 0
                          ? `: ${skillTags?.join(", ")}`
                          : ""}
                      </li>
                    );
                  })
                  .filter(Boolean) // Removes null values to avoid rendering empty <li>
              }
            </ul>
          </>
        ) : (
          enableAltText && (
            <>
              <div className="first-heading-res-left">
                {section?.name || "Skills"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Skills"
                }.`}
              />
            </>
          )
        )}
      </>
    ),
  };

  return (
    <>
      <div
        className={`resume-verivication-res ${
          userType === USER_TYPE.CANDIDATE && "resume-verivication-res-border "
        }`}
      >
        <div className="resume-start-verivication">
          <div className="all-content-resume-verification">
            <div className="name-res-left">
              {`${contactDetails?.first_name ?? ""} ${
                contactDetails?.last_name ?? ""
              }`}
            </div>
            {contactDetails?.address !== null &&
              contactDetails?.address !== "" && (
                <div className="location-res-left">
                  <p>{contactDetails?.address}</p>
                </div>
              )}
            <div className="resume-can-info-left">
              {contactDetails?.email !== "" && (
                <div className="box-one-res-l">
                  <span>Email:</span>
                  <a href="#">{contactDetails?.email}</a>
                </div>
              )}
              {contactDetails?.phone_number !== "" && (
                <div className="box-two-res-l">
                  <span>Phone:</span>
                  <a href="#">{contactDetails?.phone_number}</a>
                </div>
              )}
            </div>

            <div className="resume-content-verification">
              {/* Dynamic Resume Preview Sections */}

              {selectedSection
                ?.sort((a, b) => a?.pivot?.order - b?.pivot?.order)
                ?.map((section) =>
                  sectionResumeSessionComponents[section?.id]
                    ? sectionResumeSessionComponents[section?.id](section)
                    : null
                )}

              <div className="first-heading-res-left">References</div>
              <ul className="resume-job-list-skill">
                <li>Available on request</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mbl-resume-all">
        <div className="resume-name-mbl">
          <a href="#" target="_blank">
            {originalData?.fullName !== "" && originalData?.fullName}{" "}
          </a>
          <img
            onClick={() => handlePreviewResume(originalData?.originalResumeURL)}
            src="/image/mbl-cv-icon.png"
            alt=""
            className="preview-icon-job"
          />
        </div>
      </div>
    </>
  );
};

export default CanResumeTemp;
