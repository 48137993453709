import React, { useEffect, useState } from 'react';
import OutsideClickHandler from "react-outside-click-handler";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import CandidateJourneyCard from '../../../../components/Common/CandidateJourneyCard/CandidateJourneyCard';
import styled from 'styled-components';
import ActionModal from '../../../../components/Common/ActionModal/ActionModal';

const Column = styled.div`
  margin: 8px;
  width: 270px;
`;

const DndComponent = ({ data, setData, updateTickets, updateColumns, deleteProcess }) => {
  const [showMenu, setShowMenu] = useState(Array.from({ length: data?.columnOrder.length }, () => false));
  const [deleteColumnTable, setDeleteColumnTable] = useState(false);
  const [deleteColumnWarning, setDeleteColumnWarning] = useState(false);
  const [deleteColumnData, setDeleteColumnData] = useState('');
  const [questionModel, setQuestionModel] = useState('');
  const [copyOfResult, setCopyOfResult] = useState('');
  const onDragEnd = (result) => {
    console.log(result.destination.droppableId)
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(data.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      const newState = {
        ...data,
        columnOrder: newColumnOrder,
      };
      console.log(newColumnOrder, newState, data);

      rearrangeData(newColumnOrder, newState.columns);

      setData(newState);
      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      // console.log(start, data.columns[source.droppableId], finish)
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      return;
    }

    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setData(newState);

 
    if(updateTickets) {
      if(result.destination.droppableId === "column-2"){
        setQuestionModel(!questionModel);
        setCopyOfResult(result)
      }
      else{
        updateTickets(result)
      }
    }
  };

  const rearrangeData = (odering, data) => {
    const orderedData = {}
    odering.forEach((key) => {
      if(data.hasOwnProperty(key)){
        orderedData[key] = data[key]
      }
    });

    const transformedObject = {};
    Object.values(orderedData).forEach((item, index) => {
      transformedObject[index] = {
        id: String(item.interview_step_id),
        title: item.title,
        order: index
      };
    });
    // job-board/change-job-interview-steps-sequence
    // console.log('orderedData',transformedObject)
    if(updateColumns) {
      updateColumns(transformedObject)
    }
  }

  const handleQuestions = (val) => {
    setQuestionModel(!questionModel);
    updateTickets(copyOfResult, val)
  }

  const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return '';
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });


  const deleteColumn = (column) => {
    if(column.taskIds.length > 0) {
      setDeleteColumnWarning(!deleteColumnWarning)
    } else {
      setDeleteColumnData(column)
      setDeleteColumnTable(!deleteColumnTable)
    }
  }
  
  const deleteRow = () => {
    if(deleteProcess) {
      setDeleteColumnTable(false)
      deleteProcess(deleteColumnData)
    }
  }

  const closeModal = () => {
    setDeleteColumnWarning(false)
    setDeleteColumnTable(false);
    setQuestionModel(false);
  };
  
  const openDropMenu = (id) => {
    setShowMenu((prev) => {
      const index = data.columnOrder.indexOf(id);
      if (index !== -1) {
        const newShowMenu = [...prev];
        newShowMenu[index] = !newShowMenu[index]; // Toggle only this index
        return newShowMenu;
      }
      return prev;
    });
  }

  const closeDropMenu = (id) => {
    setShowMenu((prev) => {
      const index = data.columnOrder.indexOf(id);
      if (index !== -1) {
        const newShowMenu = [...prev];
        newShowMenu[index] = false; // Toggle only this index
        return newShowMenu;
      }
      return prev;
    });
  }

  return (
    <>
    <DragDropContext onDragEnd={onDragEnd}>
      {/* {console.log(data)} */}
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div className='d-flex' {...provided.droppableProps} ref={provided.innerRef}>
            {data?.columnOrder.map((columnId, index) => {
              const column = data.columns[columnId];
              const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);

              return (
                <Draggable
                  draggableId={column.id}
                  index={index}
                  key={column.id}
                >
                  {(provided) => (
                    <Column
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                    >
                      {/* <h3 className='m-0'>{column.title}</h3> */}
                      <div className={`drag-head ${camelize(column.title)}`}>
                        <div className='flex-between-center w-100 gap-2'>
                          <div className="flex-start-center">
                            <h3>{column.title}</h3>
                            <span className="ms-2">{column.taskIds.length}</span>
                          </div>
                          {column.title?.toLowerCase() !== 'all candidates' ? 
                            <OutsideClickHandler
                                  onOutsideClick={() => {
                                    closeDropMenu(columnId)
                                  }}
                                >
                            <div className="ellipses-dd">
                              <button className="btn btn-transparent p-0" onClick={() => openDropMenu(columnId)}>
                                <img src="/image/adJob-dd.png" alt="adJob-dd" />
                              </button>
                              {showMenu[index] === true &&
                            
                                <div className='cv-cions active'>
                             
                                  <div className="dd-val" onClick={() => deleteColumn(column)}>
                                    <img src="/image/bin.png" alt="" className="show-tooltip" />
                                    <p className="dd-content">Delete</p>
                                  </div>
                         
                                </div>
                              
                              }
                            </div> 
                            </OutsideClickHandler>
                          : ''}
                        </div>
                      </div>
                      <Droppable droppableId={column.id} type="task">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className='drag-body'
                          >
                            {tasks.map((task, index) => (
                              <Draggable
                                draggableId={task.id}
                                index={index}
                                key={task.id}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <CandidateJourneyCard data={task} />
                                    {/* {task.content} */}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </Column>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    {deleteColumnTable && (
      <ActionModal
        closeModal={closeModal}
        modalArt="/image/warning-wishlist.png"
        heading="Double-Check"
        content={`Are you sure you want to delete this ${deleteColumnData.title} step?`}
      >
        <button className="btn btn-black" onClick={() => closeModal()}>
          No
        </button>
        <button
          className="btn btn-outline-black"
          onClick={() => deleteRow()}
        >
          Yes
        </button>
      </ActionModal>
    )}
    {deleteColumnWarning && (
      <ActionModal
        closeModal={closeModal}
        modalArt="/image/warning-wishlist.png"
        heading="Attention"
        content='Please move the candidates cards to another step to delete this stage.'
      >
      </ActionModal>
    )}
   {questionModel && (
      <ActionModal
        closeModal={closeModal}
        modalArt="/image/warning-wishlist.png"
        heading="Double-Check"
        content={`Would you like to send pre-screening questions to this candidate?`}
      >
        <button className="btn btn-black" onClick={()=>handleQuestions("no")}>
          No
        </button>
        <button
          className="btn btn-outline-black"
          onClick={()=>handleQuestions("yes")}
        >
          Yes
        </button>
      </ActionModal>
    )}
    </>
  );
};

export default DndComponent;
