import React, { useState } from 'react'
import { Button, Form } from "react-bootstrap";
import APIPvtDB from "../../../../apiPvtD";
import { toast } from 'react-toastify';
import dateTime from '../../../../utils/dateTime';
import OutsideClickHandler from 'react-outside-click-handler';

const NotesSec = ({ commentDet, loggedInID, getCommentsDetail, companyInfo, editNoteSec }) => {
  // edited
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(false);
  const [showMenu, setShowMenu] = useState(false);



  const editComment = (data) => {
    setEdit(true)
    editNoteSec(data)
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const deleteComment = async (data) => {
    try {
      await APIPvtDB.delete(`/delete-note/${data}`)
        .then((response) => {
          if (response.data.success) {
            getCommentsDetail()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  const pinComment = async (data) => {
    try {
      const formData = {
        id: data
      }
      await APIPvtDB.post(`/pin-unpin-note/`, formData)
        .then((response) => {
          if (response.data.success) {
            toast.success(response.data.message, {
              autoClose: 2000,
              closeOnClick: false,
            });
            getCommentsDetail()
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }


  const dayTime = (data) => {
    const date = new Date(data);

    // Options for formatting the date
    const options = {
      weekday: 'long', // Display full weekday name
      month: 'long', // Display full month name
      day: 'numeric', // Display day of the month as a number
      year: 'numeric', // Display full year
    };
    return date.toLocaleDateString('en-US', options)
  }
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className='user-avatar'>
          <div className='avatar-img'>
            {
              commentDet?.user?.user_type === 'employer' ? <img src={companyInfo?.logo == null ? "/image/client-img.png" : companyInfo?.logo } alt="" width={40} height={40} /> : <img src={commentDet?.user?.avatar_link == null ? "/image/client-img.png" : commentDet?.user?.avatar_link} alt="" width={40} height={40} />
            }
          </div>
          <div className='avatar-desc'>{commentDet?.user?.first_name}</div>
        </div>
        <OutsideClickHandler
                          onOutsideClick={() => {
                            setShowMenu(false);
                          }}
                        >
        <div className="ellipses-dd">
          <button className="btn btn-transparent p-0" onClick={() => setShowMenu(!showMenu)}>
            <img src="/image/adJob-dd.png" alt="adJob-dd" />
          </button>
          {showMenu &&
            <div className='cv-cions active'>
              <div className="dd-val" onClick={() => editComment(commentDet)}>
                <img src="/image/edit-ep-inbox.png" alt="edit" className="show-tooltip" />
                <p className="dd-content">Edit</p>
              </div>
              <div className="dd-val" onClick={() => deleteComment(commentDet.id)}>
                <img src="/image/bin.png" alt="" className="show-tooltip" />
                <p className="dd-content">Delete</p>
              </div>
              <div className="dd-val" onClick={() => pinComment(commentDet.id)}>
                <img src="/image/pin-ep-inbox.png" alt="" className="show-tooltip" />
                <p className="dd-content">Pin</p>
              </div>
            </div>
          }
        </div> 
        </OutsideClickHandler>
      </div>
      <div className="single-note-item">
        {
          commentDet.is_pinned ? (
            <div className="pin-chat-icon">
              <img src='/image/pinned-ep-inbox.png' alt='pinned-ep-inbox' onClick={() => pinComment(commentDet?.id)}  />
            </div>
          ) : ''
        }
        <div className="note-item bg-white">
          <div className="d-flex justify-content-between align-items-start">
            <div className="w-100">
              <div className='flex-auto w-100'>
                {commentDet?.first_name && (
                  <h4>{commentDet?.first_name} {commentDet?.lasst_name}</h4>
                )}
                <div className="note-item-text">{commentDet?.comments}</div>
              </div>
            </div>
          </div>
          {/* {(commentDet.is_edited && !edit) ?
            <div className="text-end mt-3 text-edited">
              Edited: {dayTime(commentDet?.updated_at)}
            </div> : ''} */}
        </div>
        <div>
          
          <div className="text-end note-item-time mt-2">
            <p>Posted on {dateTime(commentDet?.created_at, 'date')}</p>
            <p>{dateTime(commentDet?.created_at, 'time')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesSec