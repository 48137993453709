import FactCard from "./FactCard";
import "./Company.css";
import ShowRows from "../../components/Common/Admin/ShowRows/ShowRows";
import TablePagination from "../../components/Common/Admin/TablePagination/TablePagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const navigation = useNavigate()
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "",
    value: null,
  });
  const onStatusClean = () => {
    setSelectedStatus({
      label: "",
      value: null,
    });
  };
  const onStatusClick = (label, value) => {
    setSelectedStatus({
      label: label,
      value: value,
    });
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };
    const items = Array.from({ length: 4 }, (_, index) => index + 1);
  return (
    <div className="page-space">
      <div className="page-title-heading justify-content-start mb-2 align-items-center">
        <img src="/image/form-back-arrow.png" className="me-2 cursor-pointer" />
        <h2 className="m-0">Companies </h2>
      </div>

      <div className="mt-3">
        <div className="card fact-card py-4">
          <FactCard />
          <FactCard />
          <FactCard />
          <FactCard />
        </div>
      </div>

      <div className="filter-search-bar">
        <div className="d-flex gap-3">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Province</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Province"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">City</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "City"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p
                    className={` ${
                      selectedStatus?.label !== "" && "text-filter-select"
                    }`}
                  >
                    {" "}
                    {selectedStatus?.label === ""
                      ? "Status"
                      : selectedStatus?.label}
                  </p>
                  {selectedStatus?.label === "" ? (
                    <img src="/image/admin-d.png" alt="" />
                  ) : (
                    <div
                      id="dropdown-boxId2"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src="/image/cross-port.png"
                        alt=""
                        onClick={() => onStatusClean()}
                      />
                    </div>
                  )}
                </div>
              </div>

              {status?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {status?.map((item, index) => (
                      <li
                        onClick={() => {
                          onStatusClick(item?.label, item?.value);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-right-searcgadmin">
          <div className="search-admin">
            <label
              for="jon-search-box"
              className="visibility-hidden opacity-0 position-absolute"
            >
              Search
            </label>
            <img src="/image/job-search.png" alt="search" />
            <input
              type="text"
              name="search"
              id="jon-search-box"
              placeholder="Search"
              className="form-font-f"
              onChange={(e) => {
                onSearchChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      <ShowRows perPage={perPage} />
      <div className="department-table-imge">
              <table className="depart-table-left">
                <thead className="tb-heading-deprt">
                  <tr>
                    <th scope="col">
                      <div className="th-admin-heading">Company name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Location</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Admin name</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Active jobs</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Subscription level</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Created date</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Status</div>
                    </th>
                    <th scope="col">
                      <div className="th-admin-heading">Company detail</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-admin">
                      {items.map((item) => (
                          <tr key={item}>
                          <td className="deprt-left-td">
                            Airtel
                          </td>
                          <td>
                            <div>
                            Toronto
                            </div>
                          </td>
                          <td>
                            <div>
                            Anthony Hopkins
                            </div>
                          </td>
                          <td>
                            <div>
                            25
                            </div>
                          </td>
                          <td>
                            <div>
                            Startup
                            </div>
                          </td>
                          <td>
                            <div>
                            May - 01 - 2024
                            </div>
                          </td>
                          <td>
                            <div>
                            <label className="switch" for='check'>
                              <input
                                type="checkbox"
                                // data-type={props.is_active === 0 ? false : true}
                                // checked={isActive}
                                // defaultChecked={props.is_active}
                                name='isActive'
                                // onChange={() => onSwitchChange(props)}
                                // id={`switch-${props.id}`}
                              />
                              <span className="visibility-hidden opacity-0 position-absolute">switch</span>
                              <span className="slider ml-auto"></span>
                            </label>
                            </div>
                          </td>
                          <td>
                            <div className="four-role-img">
                                <div
                                  className="img-text-tooltip"
                                  onClick={()=> navigation('/company-detail-admin')}
                                >
                                  <img
                                    src="/image/company-detail-logo.png"
                                    alt=""
                                    className="show-tooltip1 icon-cursor"
                                  />
                                  <div className="tooltip-crete-role">
                                    Company detail
                                  </div>
                                </div>  
                            </div>
                          </td>
                        </tr>
                  ))}
                                  
                </tbody>
              </table>
            
            {/* {listingData?.length > 0 && (
              <TablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            )}
            <TableError msg={msg} /> */}
        </div>
        <TablePagination 
              totalPages={1}
              currentPage={1}
            />
    </div>
  );
};

export default Company;
