import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";

/* React Quill */
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

/* Import All Template */
import CanResumeMenu from "../../../../components/Candidate/Resume/CanResumeMenu/CanResumeMenu";
import CanResumeTemp from "../../../../components/Candidate/Resume/CanResumeTemp/CanResumeTemp";
import CanResumeTempATS from "../../../../components/Candidate/Resume/CanResumeTempATS/CanResumeTempATS";
import CanResumeTempEXE from "../../../../components/Candidate/Resume/CanResumeTempEXE/CanResumeTempEXE";
import CanResumeTempSTD from "../../../../components/Candidate/Resume/CanResumeTempSTD/CanResumeTempSTD";

/* Import All Form */
import CanResumeProExpForm from "../../../../components/Candidate/Resume/CanResumeProExpForm/CanResumeProExpForm";
import CanResumeEduForm from "../../../../components/Candidate/Resume/CanResumeEduForm/CanResumeEduForm";
import CanResumeProForm from "../../../../components/Candidate/Resume/CanResumeProForm/CanResumeProForm";

/* Import Default Form */
import CanResumeCredForm from "../../../../components/Candidate/Resume/CanResumeCredForm/CanResumeCredForm";
import CanResumeSkillForm from "../../../../components/Candidate/Resume/CanResumeSkillForm/CanResumeSkillForm";

/* Import ATS Friendly Form */
import CanResumekeyCompForm from "../../../../components/Candidate/Resume/CanResumekeyCompForm/CanResumekeyCompForm";
import CanResumeCertForm from "../../../../components/Candidate/Resume/CanResumeCertForm/CanResumeCertForm";
import CanResumeTechSkillForm from "../../../../components/Candidate/Resume/CanResumeTechSkillForm/CanResumeTechSkillForm";

/* Import Executive Form */
import CanResumeAwardRecognForm from "../../../../components/Candidate/Resume/CanResumeAwardRecognForm/CanResumeAwardRecognForm";

/* Import Student Form */
import CanResumeCertActSkillsForm from "../../../../components/Candidate/Resume/CanResumeCertActSkillsForm/CanResumeCertActSkillsForm";

import WorldConnect from "../../../../components/Common/WorldConnect/WorldConnect";
/*********/

/* Import All Model */
import ResumeSaveModel from "../../../../components/Common/ResumeSaveModel/ResumeSaveModel";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import ContentModal from "../../../../components/Common/ContentModal/ContentModal";
/*********/

import {
  getCountriesAsync,
  setSelectedCustomResume,
  setUserResumes,
} from "../../../../redux/slices/AuthSlice";
import {
  getCandidateDetailResumeAsync,
  getParsedResumeAsync,
  getResumeForApplyingOnJob,
} from "../../../../redux/slices/candidateDetailSlice";
import {
  setResumeFormTab,
  getResumeDropdownDataAsync,
  createResumeAsync,
  resumeSectionUpdateAsync,
  verifyUpdateResumeAsync,
  getResumeTemplateDataAsync,
} from "../../../../redux/slices/verifyResumeSlice";
import PhoneNumber from "../../../../components/Common/PhoneNumber/PhoneNumber";
import {
  emailRegex,
  nameRegex,
  PARSING_STATUS,
  RESUME_ITEM,
  RESUME_FORM_TAB,
  RESUME_MODE,
  addressRegex,
  urlRegex,
  linkedInUrlRegex,
  scriptOnlyHTMLTagRegex,
  USER_TYPE,
  RESUME_SECTION_IDS,
  RESUME_TEMPLATE_IDS,
  phoneNoRegex,
} from "../../../../constants";
import API from "../../../../api";
import "./index.css";

const CanResume = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, userCountry, resumeDetail, selectedCustomResume } = useSelector(
    (state) => state.auth
  );
  const { resumeFormTab, resumeFormMode } = useSelector(
    (state) => state.verifyResume
  );

  const selectedResume =
    resumeFormMode === RESUME_MODE.CUSTOMIZED
      ? selectedCustomResume
      : resumeDetail;

  /* React Quill */
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [
        // { list: "unordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const allFormats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
  ];

  const headRef = useRef(null);
  const headProExpRef = useRef(null);

  const [spinner, setSpinner] = useState(false);
  const [loader, setLoader] = useState(false);

  const [activeTab, setActiveTab] = useState(resumeFormTab);
  const [originalData, setOriginalData] = useState({
    fullName: "",
    originalResumeURL: null,
  });

  /* Resume Templates */
  const [resumeCategories, setResumeCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryTemplates, setCategoryTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  /* Resume Session */
  const [proExperience, setProExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [education, setEducation] = useState([]);

  /* Resume Default Session */
  const [certifications, setCertifications] = useState([]);
  const [keySkills, setKeySkills] = useState([]);

  /* Resume ATS Friendly Session */
  const [keyCompetencies, setkeyCompetencies] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [techSkills, setTechSkills] = useState([]);

  /* Resume Executive Session */
  const [awardRecogn, setAwardRecogn] = useState("");

  /* Resume Student Session */
  const [certActSkills, setCertActSkills] = useState("");

  /* Drop Down Value*/
  const [monthDropDown, setMonthDropDown] = useState([]);
  const [yearDropDown, setYearDropDown] = useState([]);
  const [cateSkillsDropDown, setCateSkillsDropDown] = useState([]);

  /*Toggle Model*/
  const [toggleDeleteItemModel, setToggleDeleteItemModel] = useState(false);
  const [toggleSaveModel, setToggleSaveModel] = useState(false);

  /*Selected Delete Item Object*/
  const [selectedDeleteItemIndex, setSelectedDeleteItemIndex] = useState(null);
  const [selectedDeleteItemSection, setSelectedDeleteItemSection] =
    useState("");

  /*Resume File Name*/
  const [resumeDisplayName, setResumeDisplayName] = useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      address: "",
      email: "",
      phone_number: "",
      github_url: "",
      linkedin_url: "",
      candidate_summary: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(nameRegex, "First name should be an alphabet")
        .max(50, "First name cannot exceed 50 characters")
        .required("Please enter first name"),
      last_name: Yup.string()
        .matches(nameRegex, "Last name should be an alphabet")
        .max(50, "Last name cannot exceed 50 characters")
        .required("Please enter last name"),
      email: Yup.string()
        .matches(emailRegex, "Please enter valid email address")
        .max(100, "Email cannot exceed 100 characters"),
      address: Yup.string()
        .max(255, "Address cannot exceed 255 characters")
        .matches(addressRegex, "Please enter valid address"),
        phone_number: Yup.string()
        .matches(phoneNoRegex, "Please enter a valid phone number")
        .nullable()
        .notRequired(),
      github_url: Yup.string().matches(
        urlRegex,
        "Please enter a valid github url"
      ),
      linkedin_url: Yup.string().matches(
        linkedInUrlRegex,
        "Please enter a valid linkedin url"
      ),
      candidate_summary: Yup.string().matches(
        scriptOnlyHTMLTagRegex,
        "Please enter valid summary"
      ),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {},
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHeadProExp = () => {
    if (headProExpRef.current) {
      headProExpRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  /* Resume Category Template Event Handler*/

  const onCategoryChange = (category) => {
    setSelectedCategory(category);
    setCategoryTemplates(category?.templates || []);
  };

  const onTemplateChange = (template) => {
    setSelectedTemplate(template);
  };

  /* Session key Competencies Event Handler*/

  const onkeyCompAddHandler = (currentKeyComp) => {
    setkeyCompetencies([currentKeyComp, ...keyCompetencies]);
  };

  const onkeyCompDeleteHandler = (indexToRemove) => {
    const updatedKeyComp = keyCompetencies?.filter(
      (item, index) => index !== indexToRemove
    );
    setkeyCompetencies(updatedKeyComp);
  };

  /***********************/

  /* Session Professional Experience Event Handler*/

  const onProExpChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedProExperience = [...proExperience];
    updatedProExperience[indexToUpdate] = updatedObject;
    setProExperience(updatedProExperience);
  };

  const onProExpAddHandler = () => {
    const newProExp = {
      company_name: "",
      job_title: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      roles_n_responsibilities: "",
      years_of_experience: "",
      is_present: false,
      is_correct: false,
      is_remote: false,
      achievements: [],
    };

    setProExperience([newProExp, ...proExperience]);
  };

  const onProExpDeleteHandler = (indexToRemove) => {
    const updatedProExperience = proExperience?.filter(
      (item, index) => index !== indexToRemove
    );
    setProExperience(updatedProExperience);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        experience: updatedProExperience,
      },
      sessionName: "experience",
      action: "delete",
    });
  };

  const onProExpSaveHandler = async (
    newFormData = undefined,
    index = undefined
  ) => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      try {
        const toggleForm = newFormData !== undefined && index !== undefined;
        const updatedProExperience = [...proExperience];
        if (toggleForm) {
          updatedProExperience[index] = newFormData;
          setProExperience(updatedProExperience);
        }

        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            experience: toggleForm ? updatedProExperience : proExperience,
          },
          sessionName: "experience",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Project Event Handler*/

  const onProChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedProject = [...projects];
    updatedProject[indexToUpdate] = updatedObject;
    setProjects(updatedProject);
  };

  const onProAddHandler = () => {
    const newPro = {
      name: "",
      url: "",
      description: "",
    };
    setProjects([newPro, ...projects]);
  };

  const onProDeleteHandler = (indexToRemove) => {
    const updatedProject = projects?.filter(
      (item, index) => index !== indexToRemove
    );
    setProjects(updatedProject);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        projects: updatedProject,
      },
      sessionName: "projects",
      action: "delete",
    });
  };

  const onProSaveHandler = async (
    newFormData = undefined,
    index = undefined
  ) => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        const toggleForm = newFormData !== undefined && index !== undefined;
        const updatedProjects = [...projects];
        if (toggleForm) {
          updatedProjects[index] = newFormData;
          setProjects(updatedProjects);
        }
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            projects: toggleForm ? updatedProjects : projects,
          },
          sessionName: "projects",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Education Event Handler*/

  const onEduChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedEdu = [...education];
    updatedEdu[indexToUpdate] = updatedObject;
    setEducation(updatedEdu);
  };

  const onEduAddHandler = () => {
    const newEdu = {
      degree: "",
      institute: "",
      city: "",
      country: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_present: false,
      is_correct: false,
    };

    setEducation([newEdu, ...education]);
  };

  const onEduDeleteHandler = (indexToRemove) => {
    const updatedEdu = education?.filter(
      (item, index) => index !== indexToRemove
    );
    setEducation(updatedEdu);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        education: updatedEdu,
      },
      sessionName: "education",
      action: "delete",
    });
  };

  const onEduSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            education: education,
          },
          sessionName: "education",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Certificates Event Handler*/

  const onCertChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedCert = [...certificates];
    updatedCert[indexToUpdate] = updatedObject;
    setCertificates(updatedCert);
  };

  const onCertAddHandler = () => {
    const newCert = {
      name: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_correct: false,
    };

    setCertificates([newCert, ...certificates]);
  };

  const onCertDeleteHandler = (indexToRemove) => {
    const updatedCert = certificates?.filter(
      (item, index) => index !== indexToRemove
    );
    setCertificates(updatedCert);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        certificates: updatedCert,
      },
      sessionName: "certificates",
      action: "delete",
    });
  };

  const onCertSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            certificates: certificates,
          },
          sessionName: "certificates",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Certifications Event Handler*/

  const onCredChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedCred = [...certifications];
    updatedCred[indexToUpdate] = updatedObject;
    setCertifications(updatedCred);
  };

  const onCredAddHandler = () => {
    const newCred = {
      name: "",
      start_date: {
        month: "",
        year: "",
      },
      end_date: {
        month: "",
        year: "",
      },
      is_correct: false,
    };

    setCertifications([newCred, ...certifications]);
  };

  const onCredDeleteHandler = (indexToRemove) => {
    const updatedCred = certifications?.filter(
      (item, index) => index !== indexToRemove
    );
    setCertifications(updatedCred);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        certifications: updatedCred,
      },
      sessionName: "certifications",
      action: "delete",
    });
  };

  const onCredSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            certifications: certifications,
          },
          sessionName: "certifications",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Skill Event Handler*/

  const onSkillsChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedKeySkills = [...keySkills];
    updatedKeySkills[indexToUpdate] = updatedObject;
    setKeySkills(updatedKeySkills);
  };

  const onSkillsAddHandler = () => {
    const newSkills = {
      name: "",
      tags: [],
    };
    setKeySkills([newSkills, ...keySkills]);
  };

  const onSkillsDeleteHandler = (indexToRemove) => {
    const updatedSkills = keySkills?.filter(
      (item, index) => index !== indexToRemove
    );
    setKeySkills(updatedSkills);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        key_skills: updatedSkills,
      },
      sessionName: "key_skills",
      action: "delete",
    });
  };

  const onSkillsSaveHandler = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            key_skills: keySkills,
          },
          sessionName: "key_skills",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /* Session Technical Skills  Event Handler*/

  const onTechSkillsChangeHandler = (updatedObject, indexToUpdate) => {
    const updatedTechSkills = [...techSkills];
    updatedTechSkills[indexToUpdate] = updatedObject;
    setTechSkills(updatedTechSkills);
  };

  const onTechSkillsAddHandler = () => {
    const newTechSkills = {
      name: "",
    };
    setTechSkills([newTechSkills, ...techSkills]);
  };

  const onTechSkillsDeleteHandler = (indexToRemove) => {
    const updatedTechSkills = techSkills?.filter(
      (item, index) => index !== indexToRemove
    );
    setTechSkills(updatedTechSkills);

    resumeSectionUpdate({
      userId: user?.id,
      resumeData: {
        contact_details: formik.values,
        technical_skills: updatedTechSkills,
      },
      sessionName: "technical_skills",
      action: "delete",
    });
  };

  const onTechSkillsSaveHandler = async (
    newFormData = undefined,
    index = undefined
  ) => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();
    if (Object.keys(isFormValid).length === 0) {
      try {
        const toggleForm = newFormData !== undefined && index !== undefined;
        const updatedTechSkills = [...techSkills];
        if (toggleForm) {
          updatedTechSkills[index] = newFormData;
          setTechSkills(updatedTechSkills);
        }
        resumeSectionUpdate({
          userId: user?.id,
          resumeData: {
            contact_details: formik.values,
            technical_skills: toggleForm ? updatedTechSkills : projects,
          },
          sessionName: "technical_skills",
          action: "update",
        });
      } catch (error) {}
    } else {
      scrollToHead();
    }
  };

  /***********************/

  /*******Awards & Recognition Event Handler*********/

  const onAwardRecognChangeHandler = (updatedValue) => {
    setAwardRecogn(updatedValue);
  };

  /***********************/

  /*******Student Event Handler*********/

  const onCertActSkillsChangeHandler = (updatedValue) => {
    setCertActSkills(updatedValue);
  };

  /***********************/

  /*******Resume File Name Event Handler*********/

  const onFileNameChangeHandler = (updatedObject) => {
    const { resume_display_name } = updatedObject;
    setResumeDisplayName(resume_display_name);
  };

  /***********************/

  /*******Model Event Handler*********/

  const deleteItemModelHandler = (v) => setToggleDeleteItemModel(v);

  const saveModelHandler = (v) => setToggleSaveModel(v);

  /***********************/

  /**********Delete Item Model************/

  const openDeleteItemHandler = (indexToUpdate, session, toggleModel) => {
    setSelectedDeleteItemIndex(indexToUpdate);
    setSelectedDeleteItemSection(session);
    deleteItemModelHandler(toggleModel);
  };

  const closeDeleteItemHandler = () => {
    setSelectedDeleteItemIndex(null);
    setSelectedDeleteItemSection("");
    deleteItemModelHandler(false);
  };

  const selectedDeleteItemHandler = () => {
    if (selectedDeleteItemSection === RESUME_ITEM.PROFESSIONAL_EXPERIENCE) {
      onProExpDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.PROJECT) {
      onProDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.EDUCATION) {
      onEduDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.CERTIFICATES) {
      onCertDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.CERTIFICATION) {
      onCredDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.SKILLS) {
      onSkillsDeleteHandler(selectedDeleteItemIndex);
    } else if (selectedDeleteItemSection === RESUME_ITEM.TECHNICAL_SKILLS) {
      onTechSkillsDeleteHandler(selectedDeleteItemIndex);
    }
  };

  /***********************/

  const getResumes = async (token) => {
    if (token) {
      dispatch(getResumeForApplyingOnJob({ token: token }))
        .then(async (response) => {
          const res = response?.payload?.data;
          dispatch(setUserResumes(res));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onVerifyUpdateResumeClick = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      const hasExpError = proExperience?.some(
        (item) => item?.is_correct === false
      );

      if (hasExpError) {
        toast.warning(
          "Please complete the work experience dates in the highlighted section",
          {
            autoClose: 2000,
            closeOnClick: false,
          }
        );
        scrollToHeadProExp();
      } else {
        setLoader(true);

        // Initialize resumeData
        const resumeData = {};

        // Always include contact_details
        resumeData.contact_details = {
          first_name: formik?.values?.first_name,
          last_name: formik?.values?.last_name,
          address: formik?.values?.address,
          email: formik?.values?.email,
          phone_number: formik?.values?.phone_number,
          github_url: formik?.values?.github_url,
          linkedin_url: formik?.values?.linkedin_url,
        };

        // If section ID 1 (Summary) is selected, add candidate_summary to contact_details
        if (
          selectedTemplate?.sections?.some(
            (section) => section?.id === RESUME_SECTION_IDS.SUMMARY
          )
        ) {
          resumeData.contact_details.candidate_summary =
            formik?.values?.candidate_summary;
        }

        // Include other resume sections dynamically
        selectedTemplate?.sections?.forEach((section) => {
          switch (section?.id) {
            case RESUME_SECTION_IDS.KEY_COMPETENCIES:
              resumeData.key_competencies = keyCompetencies;
              break;
            case RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE:
              resumeData.experience = proExperience;
              break;
            case RESUME_SECTION_IDS.PROJECTS:
              resumeData.projects = projects;
              break;
            case RESUME_SECTION_IDS.EDUCATION:
              resumeData.education = education;
              break;
            case RESUME_SECTION_IDS.CERTIFICATES:
              resumeData.certificates = certificates;
              break;
            case RESUME_SECTION_IDS.CERTIFICATIONS:
              resumeData.certifications = certifications;
              break;
            case RESUME_SECTION_IDS.SKILLS:
              resumeData.key_skills = keySkills;
              break;
            case RESUME_SECTION_IDS.TECHNICAL_SKILLS:
              resumeData.technical_skills = techSkills;
              break;
            case RESUME_SECTION_IDS.CERTIFICATES_ACTIVITIES_SKILLS:
              resumeData.certificates_activities_skills = certActSkills;
              break;
            case RESUME_SECTION_IDS.AWARDS_RECOGNITION:
              resumeData.awards_recognitions = awardRecogn;
              break;
            default:
              break;
          }
        });

        let dispatchFunction;
        let dispatchObject;

        if (resumeFormMode === RESUME_MODE.CREATE) {
          dispatchFunction = createResumeAsync;
          dispatchObject = {
            userId: user?.id,
            resumeData,
            templateId: selectedTemplate?.id ? selectedTemplate?.id : 1,
            resumeDisplayName: resumeDisplayName,
          };
        } else {
          dispatchFunction = verifyUpdateResumeAsync;
          dispatchObject = {
            userId: user?.id,
            resumeData,
            resumeId: selectedResume?.resumeId,
            resumeType: selectedResume?.resumeType,
            templateId: selectedTemplate?.id ? selectedTemplate?.id : 1,
            resumeDisplayName: resumeDisplayName,
          };
        }

        dispatch(dispatchFunction(dispatchObject))
          .then((response) => {
            const res = response?.payload;
            const token = localStorage.getItem("token");
            if (res?.result === true) {
              setLoader(false);
              toast.success(res?.message?.success, {
                autoClose: 2000,
                closeOnClick: false,
              });

              if (resumeFormMode === RESUME_MODE.CREATE) {
                navigate("/my-resumes");
              }
              if (
                resumeFormMode === RESUME_MODE.UPDATE ||
                resumeFormMode === RESUME_MODE.CHOOSE_TEMPLATES
              ) {
                scrollToHead();
              }
              if (resumeFormMode === RESUME_MODE.CUSTOMIZED) {
                dispatch(
                  setSelectedCustomResume({
                    resumeId: null,
                    resumeType: 2,
                  })
                );
                navigate("/my-resumes");
              }
              getResumes(token);
            } else {
              setLoader(false);
              const errorMsg = res?.message?.error;

              if (errorMsg) {
                toast.error(errorMsg, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }
            }
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
          });
      }
    } else {
      setLoader(false);
      scrollToHead();
    }
  };

  const onCustomResumeFileNameClick = async () => {
    formik.handleSubmit();
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      const hasExpError = proExperience?.some(
        (item) => item?.is_correct === false
      );

      if (hasExpError) {
        toast.warning(
          "Please complete the work experience dates in the highlighted section",
          {
            autoClose: 2000,
            closeOnClick: false,
          }
        );
        scrollToHeadProExp();
      } else {
        saveModelHandler(true);
      }
    } else {
      scrollToHead();
    }
  };

  const onCustomResumeClick = async () => {
    onVerifyUpdateResumeClick();
  };

  const resumeSectionUpdate = async (rawData) => {
    if(resumeFormMode !== RESUME_MODE.CREATE){
      const newRawData = {
        ...rawData,
        resumeId: selectedResume?.resumeId,
        resumeType: selectedResume?.resumeType,
      };
      dispatch(resumeSectionUpdateAsync(newRawData))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            toast.success(res?.message?.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
          } else {
            toast.error(res?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
          }
          closeDeleteItemHandler();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      toast.success(
        `${selectedDeleteItemSection.charAt(0).toUpperCase()}${selectedDeleteItemSection.slice(1)} deleted successfully`, 
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      closeDeleteItemHandler();
    }
   
  };

  const getParsedResumeData = async () => {
    if (user?.id !== null) {
      setSpinner(true);
      dispatch(
        getParsedResumeAsync({
          userId: user?.id,
          resumeId: selectedResume?.resumeId,
        })
      )
        .then(async (response) => {
          setSpinner(false);
          const res = response?.payload;
          const { latest_resume } = res;
          const { template } = res;
          const {
            contact_details,
            key_competencies,
            experience,
            projects,
            education,
            certificates,
            certifications,
            key_skills,
            technical_skills,
            awards_recognitions,
            certificates_activities_skills,
            parsing_status,
            parsing_message,
          } = res?.resume_data;

          if (
            parsing_status?.toLowerCase() === PARSING_STATUS.SUCCESS ||
            parsing_status?.toLowerCase() === PARSING_STATUS.COMPLETED
          ) {
            await formik.setFieldValue(
              "first_name",
              contact_details?.first_name !== null
                ? contact_details?.first_name
                : ""
            );
            await formik.setFieldValue(
              "last_name",
              contact_details?.last_name != null
                ? contact_details?.last_name
                : ""
            );
            await formik.setFieldValue(
              "address",
              contact_details?.address !== null ? contact_details?.address : ""
            );
            await formik.setFieldValue(
              "email",
              contact_details?.email !== null ? contact_details?.email : ""
            );
            await formik.setFieldValue(
              "phone_number",
              contact_details?.phone_number !== null
                ? contact_details?.phone_number
                : ""
            );
            await formik.setFieldValue(
              "github_url",
              contact_details?.github_url !== null
                ? contact_details?.github_url
                : ""
            );
            await formik.setFieldValue(
              "linkedin_url",
              contact_details?.linkedin_url !== null
                ? contact_details?.linkedin_url
                : ""
            );

            await formik.setFieldValue(
              "candidate_summary",
              typeof contact_details?.candidate_summary === "string"
                ? contact_details?.candidate_summary
                : ""
            );

            setkeyCompetencies(
              Array.isArray(key_competencies) ? key_competencies : []
            );

            setProExperience(Array.isArray(experience) ? experience : []);

            setProjects(Array.isArray(projects) ? projects : []);

            setEducation(Array.isArray(education) ? education : []);

            setCertificates(Array.isArray(certificates) ? certificates : []);

            setCertifications(
              Array.isArray(certifications) ? certifications : []
            );

            setKeySkills(Array.isArray(key_skills) ? key_skills : []);

            setTechSkills(
              Array.isArray(technical_skills) ? technical_skills : []
            );

            setCertActSkills(
              typeof certificates_activities_skills === "string"
                ? certificates_activities_skills
                : ""
            );

            setAwardRecogn(
              typeof awards_recognitions === "string" ? awards_recognitions : ""
            );

            /* Resume Template */
            if (template) setSelectedTemplate(template);
          } else if (
            parsing_status?.toLowerCase() === PARSING_STATUS.PENDIND ||
            parsing_status?.toLowerCase() === PARSING_STATUS.PROCESS
          ) {
          } else {
          }
          setOriginalData({
            fullName: `${contact_details?.first_name} ${contact_details?.last_name}`,
            originalResumeURL: latest_resume?.resume_link,
          });

          setResumeDisplayName(
            res?.resume_display_name !== null ? res?.resume_display_name : ""
          );
          scrollToHead();
        })
        .catch((error) => {
          setSpinner(false);
          console.log(error);
        });
    }
  };

  const getCandidateDetailResumeData = async () => {
    if (user?.id !== null) {
      dispatch(getCandidateDetailResumeAsync())
        .then(async (response) => {
          const res = response?.payload;
          const { data } = res;
          if (res?.success === true) {
            await formik.setFieldValue(
              "first_name",
              data?.first_name !== null ? data?.first_name : ""
            );
            await formik.setFieldValue(
              "last_name",
              data?.last_name != null ? data?.last_name : ""
            );
            await formik.setFieldValue(
              "address",
              data?.address !== null ? data?.address : ""
            );
            await formik.setFieldValue(
              "email",
              data?.email !== null ? data?.email : ""
            );
            await formik.setFieldValue(
              "phone_number",
              data?.phone !== null ? data?.phone : ""
            );
            await formik.setFieldValue(
              "linkedin_url",
              data?.linkedin !== null ? data?.linkedin : ""
            );
          }
          scrollToHead();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const getResumeDropdownData = () => {
    try {
      dispatch(getResumeDropdownDataAsync())
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            setMonthDropDown(res?.month_names);
            setYearDropDown(res?.yearsList);
            setCateSkillsDropDown(res?.skillTagCategories);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getResumeTemplateData = () => {
    if (user?.id !== null) {
      try {
        dispatch(
          getResumeTemplateDataAsync({
            user_id: user?.id,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.result === true) {
              const categories = res?.data || [];
              setResumeCategories(categories);

              if (categories.length > 0) {
                setSelectedCategory(categories[0]);

                const templates = categories[0]?.templates || [];
                setCategoryTemplates(templates);

                if (templates.length > 0) {
                  if (resumeFormMode === RESUME_MODE.CREATE) {
                    setSelectedTemplate(templates[0]);
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (resumeFormMode === RESUME_MODE.CREATE) {
      getCandidateDetailResumeData();
    }
  }, []);

  useEffect(() => {
    scrollToHead();
    getResumeDropdownData();
    getResumeTemplateData();
    if (resumeFormMode !== RESUME_MODE.CREATE) {
      getParsedResumeData();
    }
  }, []);

  const sectionResumeFormComponents = {
    [RESUME_SECTION_IDS.SUMMARY]: (section) => (
      <>
        <div
          className={`address-res f-feild-res ${
            isFormFieldValid("candidate_summary") && "error-input"
          }`}
        >
          <label for="lname">{section?.name || "Summary"}</label>
          <br />
          <ReactQuill
            theme="snow"
            className={`${
              isFormFieldValid("candidate_summary") && "error-input"
            }`}
            value={formik?.values?.candidate_summary ?? ""}
            name="JobDes"
            modules={modules}
            formats={allFormats}
            onChange={(data) => {
              formik.setFieldValue("candidate_summary", data);
              formik.handleChange("candidate_summary");
            }}
            onBlur={() => {
              formik.handleBlur("candidate_summary");
            }}
          />

          {getFormErrorMessage("candidate_summary")}
        </div>
      </>
    ),
    [RESUME_SECTION_IDS.KEY_COMPETENCIES]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Key Competencies"}
        </div>
        <CanResumekeyCompForm
          keyCompetencies={keyCompetencies}
          onkeyCompAddHandler={onkeyCompAddHandler}
          onkeyCompDeleteHandler={onkeyCompDeleteHandler}
        />
      </div>
    ),
    [RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE]: (section) => (
      <div className="experince-resume-right" ref={headProExpRef}>
        <div className="experience-info-right">
          {section?.name || "Professional Experience"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onProExpAddHandler()}
          />
        </div>
        {proExperience?.length > 0 &&
          proExperience?.map((prod, index) => (
            <CanResumeProExpForm
              prod={prod}
              index={index}
              totalProExperience={proExperience?.length}
              monthDropDown={monthDropDown}
              yearDropDown={yearDropDown}
              onProExpChangeHandler={onProExpChangeHandler}
              onProExpSaveHandler={onProExpSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.PROJECTS]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Projects"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onProAddHandler()}
          />
        </div>

        {projects?.length > 0 &&
          projects?.map((prod, index) => (
            <CanResumeProForm
              prod={prod}
              index={index}
              totalProjects={projects?.length}
              onProChangeHandler={onProChangeHandler}
              onProSaveHandler={onProSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.EDUCATION]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Education"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onEduAddHandler()}
          />
        </div>

        {education?.length > 0 &&
          education?.map((prod, index) => (
            <CanResumeEduForm
              prod={prod}
              index={index}
              totalEducation={education?.length}
              monthDropDown={monthDropDown}
              yearDropDown={yearDropDown}
              onEduChangeHandler={onEduChangeHandler}
              onEduSaveHandler={onEduSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.CERTIFICATES]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Certificates"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onCertAddHandler()}
          />
        </div>
        {certificates?.length > 0 &&
          certificates?.map((prod, index) => (
            <CanResumeCertForm
              prod={prod}
              index={index}
              totalCertificates={certificates?.length}
              monthDropDown={monthDropDown}
              yearDropDown={yearDropDown}
              onCertChangeHandler={onCertChangeHandler}
              onCertSaveHandler={onCertSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.CERTIFICATIONS]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Certifications"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onCredAddHandler()}
          />
        </div>
        {certifications?.length > 0 &&
          certifications?.map((prod, index) => (
            <CanResumeCredForm
              prod={prod}
              index={index}
              totalCertifications={certifications?.length}
              monthDropDown={monthDropDown}
              yearDropDown={yearDropDown}
              onCredChangeHandler={onCredChangeHandler}
              onCredSaveHandler={onCredSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.SKILLS]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Skills"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onSkillsAddHandler()}
          />
        </div>
        {keySkills?.length > 0 &&
          keySkills?.map((prod, index) => (
            <CanResumeSkillForm
              prod={prod}
              index={index}
              totalKeySkills={keySkills?.length}
              cateSkillsDropDown={cateSkillsDropDown}
              onSkillsChangeHandler={onSkillsChangeHandler}
              onSkillsSaveHandler={onSkillsSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.TECHNICAL_SKILLS]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Technical Skills"}{" "}
          <img
            className="icon-cursor"
            src="/image/plus-res.png"
            alt=""
            onClick={() => onTechSkillsAddHandler()}
          />
        </div>

        {techSkills?.length > 0 &&
          techSkills?.map((prod, index) => (
            <CanResumeTechSkillForm
              prod={prod}
              index={index}
              totalTechSkills={techSkills?.length}
              onTechSkillsChangeHandler={onTechSkillsChangeHandler}
              onTechSkillsSaveHandler={onTechSkillsSaveHandler}
              openDeleteItemHandler={openDeleteItemHandler}
            />
          ))}
      </div>
    ),
    [RESUME_SECTION_IDS.CERTIFICATES_ACTIVITIES_SKILLS]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {section?.name || "Certificates/Activities/Skills"}{" "}
        </div>
        <CanResumeCertActSkillsForm
          certActSkills={certActSkills}
          onCertActSkillsChangeHandler={onCertActSkillsChangeHandler}
        />
      </div>
    ),
    [RESUME_SECTION_IDS.AWARDS_RECOGNITION]: (section) => (
      <div className="experince-resume-right">
        <div className="experience-info-right">
          {" "}
          {section?.name || "Awards & Recognition"}{" "}
        </div>
        <CanResumeAwardRecognForm
          awardRecogn={awardRecogn}
          onAwardRecognChangeHandler={onAwardRecognChangeHandler}
        />
      </div>
    ),
  };

  const sectionResumePreviewComponents = {
    [RESUME_TEMPLATE_IDS.DEFAULT]: () => (
      <CanResumeTemp
        enableAltText={true}
        userType={USER_TYPE.CANDIDATE}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={formik.values}
        proExperience={proExperience}
        projects={projects}
        education={education}
        certifications={certifications}
        keySkills={keySkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.STUDENT]: () => (
      <CanResumeTempSTD
        enableAltText={true}
        userType={USER_TYPE.CANDIDATE}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={formik.values}
        proExperience={proExperience}
        education={education}
        certActSkills={certActSkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.CLASSIC_CORPORATE]: () => (
      <CanResumeTempATS
        enableAltText={true}
        userType={USER_TYPE.CANDIDATE}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={formik.values}
        keyCompetencies={keyCompetencies}
        proExperience={proExperience}
        projects={projects}
        education={education}
        certificates={certificates}
        certifications={certifications}
        keySkills={keySkills}
        techSkills={techSkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.EXECUTIVE]: () => (
      <CanResumeTempEXE
        enableAltText={true}
        userType={USER_TYPE.CANDIDATE}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={formik.values}
        proExperience={proExperience}
        education={education}
        certifications={certifications}
        keySkills={keySkills}
        awardRecogn={awardRecogn}
      />
    ),
  };

  return (
    <>
      {spinner ? (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          <div className="container container-site py-md-2 py-1">
            <Button
              variant="link"
              className="back-to-home add position-relative top-0"
              onClick={() => navigate(-1)}
            >
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
          </div>
          <section className="journey-sec pt-0" ref={headRef}>
            <div className="position-relative">
              <div className="job-heading">
                <div className="container container-site position-relative">
                  <h2>
                    {" "}
                    {resumeFormMode === RESUME_MODE.CREATE
                      ? "Create resume"
                      : resumeFormMode === RESUME_MODE.UPDATE
                      ? "Update resume"
                      : resumeFormMode === RESUME_MODE.CUSTOMIZED
                      ? "Customize resume"
                      : resumeFormMode === RESUME_MODE.CHOOSE_TEMPLATES
                      ? "Choose templates"
                      : ""}
                  </h2>
                </div>
              </div>
              <div className="container container-site cv-toggler">
                <div className="jour-div-e">
                  <div className="jres-verification-row">
                    <div className="toogle-btn-res">
                      <button
                        onClick={() => {
                          setActiveTab(RESUME_FORM_TAB.EDIT);
                          dispatch(setResumeFormTab(RESUME_FORM_TAB.EDIT));
                        }}
                        className={
                          activeTab === RESUME_FORM_TAB.EDIT
                            ? "template-btn active-toggle-btn-ver"
                            : "template-btn"
                        }
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setActiveTab(RESUME_FORM_TAB.PREVIEW);
                          dispatch(setResumeFormTab(RESUME_FORM_TAB.PREVIEW));
                        }}
                        className={
                          activeTab === RESUME_FORM_TAB.PREVIEW
                            ? "template-btn active-toggle-btn-ver"
                            : "template-btn"
                        }
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container container-site">
              {activeTab === "edit" ? (
                <div className="resume-verivication-form">
                  <div className="resume-form-info">
                    <div className="personal-info-right">
                      Personal Information
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res ${
                            isFormFieldValid("first_name") && "error-input"
                          }`}
                        >
                          <label for="fname">First Name</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("first_name")}
                        </div>
                        <div
                          className={`last-name-res ${
                            isFormFieldValid("last_name") && "error-input"
                          }`}
                        >
                          <label for="lname">Last Name</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="text"
                            placeholder="Last Name"
                            name="last_name "
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("last_name")}
                        </div>
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("address") && "error-input"
                        }`}
                      >
                        <label for="lname">Address</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="text"
                          placeholder="Address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("address")}
                      </div>
                      <div className="resume-personal-form f-feild-res">
                        <div
                          className={`first-name-res f-feild-res ${
                            isFormFieldValid("email") && "error-input"
                          }`}
                        >
                          <label for="lname">E-Mail</label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="email"
                            name="email"
                            id=""
                            placeholder="Enter valid email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("email")}
                        </div>
                        <div
                          className={`first-name-res f-feild-res ${
                            isFormFieldValid("phone_number") && "error-input"
                          }`}
                        >
                          <label for="lname">Phone </label>
                          <br />
                          <input
                            className="feild-stl-res form-font-f"
                            type="text"
                            name="phone_number"
                            id=""
                            placeholder="Phone"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {getFormErrorMessage("phone_number")}
                        </div>
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("github_url") && "error-input"
                        }`}
                      >
                        <label for="lname">Github/Portfolio Link</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="url"
                          name="github_url"
                          id=""
                          placeholder="Enter Your Github/Portfolio Profile Here"
                          value={formik.values.github_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("github_url")}
                      </div>
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("linkedin_url") && "error-input"
                        }`}
                      >
                        <label for="lname">LinkedIn</label>
                        <br />
                        <input
                          className="feild-stl-res form-font-f"
                          type="text"
                          id=""
                          placeholder="Enter your linkedIn profile here"
                          name="linkedin_url"
                          value={formik.values.linkedin_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("linkedin_url")}
                      </div>

                      {/* Dynamic Resume Form Sections */}

                      {selectedTemplate?.sections
                        ?.sort((a, b) => a?.pivot?.order - b?.pivot?.order)
                        ?.map((section) =>
                          sectionResumeFormComponents[section?.id]
                            ? sectionResumeFormComponents[section?.id](section)
                            : null
                        )}

                      <div className="address-res f-feild-res">
                        <div className="two-can-sav-btn-last">
                          <button
                            type="button"
                            className="can-res-btn-last"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="save-res-btn-last"
                            onClick={() => {
                              if (loader) {
                              } else {
                                if (
                                  resumeFormMode === RESUME_MODE.CREATE ||
                                  resumeFormMode === RESUME_MODE.CUSTOMIZED
                                ) {
                                  onCustomResumeFileNameClick();
                                } else if (
                                  resumeFormMode === RESUME_MODE.UPDATE ||
                                  resumeFormMode ===
                                    RESUME_MODE.CHOOSE_TEMPLATES
                                ) {
                                  onVerifyUpdateResumeClick();
                                }
                              }
                            }}
                          >
                            {loader ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  <CanResumeMenu
                    resumeCategories={resumeCategories}
                    selectedCategory={selectedCategory}
                    categoryTemplates={categoryTemplates}
                    selectedTemplate={selectedTemplate}
                    onCategoryChange={onCategoryChange}
                    onTemplateChange={onTemplateChange}
                  />

                  {/* Dynamic Resume Preview Sections */}

                  {selectedTemplate ? (
                    sectionResumePreviewComponents[selectedTemplate?.id] ? (
                      sectionResumePreviewComponents[selectedTemplate?.id]()
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </section>
          <WorldConnect />

          {toggleSaveModel && (
            <ContentModal closeModal={saveModelHandler}>
              <ResumeSaveModel
                modelHandler={saveModelHandler}
                onFileNameChangeHandler={onFileNameChangeHandler}
                onCustomResumeClick={onCustomResumeClick}
              />
            </ContentModal>
          )}

          {toggleDeleteItemModel && (
            <ActionModal
              closeModal={closeDeleteItemHandler}
              modalArt="/image/warning-wishlist.png"
              heading="Double-Check"
              content={`Are you sure you want to delete this ${selectedDeleteItemSection}?`}
            >
              <button
                className="btn btn-black"
                onClick={() => closeDeleteItemHandler()}
              >
                No
              </button>
              <button
                className="btn btn-outline-black"
                onClick={() => selectedDeleteItemHandler()}
              >
                Yes
              </button>
            </ActionModal>
          )}
        </>
      )}
    </>
  );
};

export default CanResume;
