import React from 'react'

const FactCard = () => {
  return (
    <div className='company-facts'>
      <div className='fact-header'>
        <div className='d-flex justify-content-between gap-2'>
          <div>
            <h6>Total subscriptions </h6>
            <h2>425</h2>
          </div>
          <img src="/image/subscription.png" alt='form-back-arrow.png' />
        </div>
      </div>
      <div className='fact-body'>
        <ul>
          <li>
            <p>Free</p>
            <p><strong>450</strong></p>
          </li>
          <li>
            <p>Free</p>
            <p><strong>450</strong></p>
          </li>
          <li>
            <p>Free</p>
            <p><strong>450</strong></p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FactCard