import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import API from "../../api";
import { RESUME_FORM_TAB, RESUME_MODE } from "../../constants";

const initialState = {
  resumeFormTab: RESUME_FORM_TAB.PREVIEW,
  resumeFormMode: RESUME_MODE.UPLOAD,
};
const verifyResumeSlice = createSlice({
  name: "verifyResume",
  initialState,
  reducers: {
    setResumeFormTab: (state, action) => {
      state.resumeFormTab = action.payload;
    },
    setResumeFormMode: (state, action) => {
      state.resumeFormMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setResumeFormTab, setResumeFormMode } = verifyResumeSlice.actions;

export default verifyResumeSlice.reducer;

// asynchronous function with createAsyncThunk

export const getResumeDropdownDataAsync = createAsyncThunk(
  "candidate/getResumeDropdownData",
  async () => {
    try {
      const response = await API.get(`/get-months-years`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getResumeTemplateDataAsync = createAsyncThunk(
  "candidate/getResumeTemplateData",
  async ( {user_id }) => 
    {
    try {
      let params = `?`;
      if (user_id !== null) {
        params += `user_id=${user_id}`;
      }
      const response = await API.get(`/job-board/fetch-templates${params}`);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const resumeSectionUpdateAsync = createAsyncThunk(
  "candidate/resumeSection",
  async ({ userId, resumeData, resumeId, resumeType, sessionName, action }) => {
    const rawData = {
      resume_data: resumeData,
      candidate_id: userId,
      section_name: sessionName,
      action: action,
      resume_id: resumeId,
      resume_type: resumeType,
    };

    console.log("rawData", rawData);
    try {
      const response = await API.post("/auth/update-resume-section", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const regenerateResumeAIAsync = createAsyncThunk(
  "candidate/regenerateResumeAI",
  async ({
    userId,
    sectionName,
    jobTitle,
    sectionValue,
    promptInput,
    aiGen,
    isPresent,
  }) => {
    const formData = new FormData();

    if (userId !== null) {
      formData.append("candidate_id", userId);
    }
    if (sectionName !== "") {
      formData.append("section_name", sectionName);
    }
    if (jobTitle !== null) {
      formData.append("job_title", jobTitle);
    }
    if (sectionValue !== "") {
      formData.append("section_value", sectionValue);
    }
    if (promptInput !== "") {
      formData.append("prompt_input", promptInput);
    }
    if (aiGen !== "") {
      formData.append("ai_gen", aiGen);
    }
    if (isPresent !== null) {
      formData.append("is_present", isPresent);
    }

    try {
      const response = await API.post(
        "/auth/update-section-wo-jd",
        formData
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);


export const regenerateResumeListAIAsync = createAsyncThunk(
  "candidate/regenerateResumeListAI",
  async ({
    userId,
    sectionName,
    jobTitle,
    sectionValue,
    promptInput,
    aiGen,
    isPresent,
  }) => {
    const formData = new FormData();

    if (userId !== null) {
      formData.append("candidate_id", userId);
    }
    if (sectionName !== "") {
      formData.append("section_name", sectionName);
    }
    if (jobTitle !== null) {
      formData.append("job_title", jobTitle);
    }
    if (sectionValue !== "") {
      formData.append("section_value", sectionValue);
    }
    if (promptInput !== "") {
      formData.append("prompt_input", promptInput);
    }
    if (aiGen !== "") {
      formData.append("ai_gen", aiGen);
    }
    if (isPresent !== null) {
      formData.append("is_present", isPresent);
    }

    try {
      const response = await API.post(
        `/auth/update-section-wo-jd-list`,
        formData
      );

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      const { response } = error;
      const { data } = response;

      if (typeof data?.message === "string") {
        toast.error(data?.message, {
          autoClose: 2000,
          closeOnClick: false,
        });
      } else if (data?.message?.error) {
        toast.error(data?.message?.error, {
          autoClose: 2000,
          closeOnClick: false,
        });
      }
      throw error;
    }
  }
);

export const createResumeAsync = createAsyncThunk(
  "candidate/createResume",
  async ({ userId, resumeData, templateId, resumeDisplayName }) => {
    const rawData = {
      resume_data: { ...resumeData, achievements: [], hobbies: [] },
      candidate_id: userId,
      template_id: templateId,
      resume_display_name: resumeDisplayName,
    };

    try {
      const response = await API.post("/auth/create-resume", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const verifyUpdateResumeAsync = createAsyncThunk(
  "candidate/verifyResume",
  async ({ userId, resumeData, resumeId, resumeType, templateId, resumeDisplayName }) => {
    const rawData = {
      resume_data: { ...resumeData, achievements: [], hobbies: [] },
      candidate_id: userId,
      resume_id: resumeId,
      resume_type: resumeType,
      template_id: templateId,
      resume_display_name: resumeDisplayName,
    };

    try {
      const response = await API.post("/auth/verify-update-resume", rawData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const customResumeAsync = createAsyncThunk(
  "candidate/customResumeAsync",
  async ({ userId, resume, JobURL, JobDescription }) => {
    const formData = new FormData();
    formData.append("candidate_id", userId);
    formData.append("resume", resume);
    formData.append("job_url", JobURL);
    formData.append("job_description", JobDescription);

    try {
      const response = await API.post(`/auth/customize-my-resume`, formData);

      if (response?.status === 200 || response?.status === 201) {
        return response?.data;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      throw error;
    }
  }
);
