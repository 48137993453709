import { useState } from 'react';
import './BlogListing.css'

const BlogCard = () => {
    const [showMenu, setShowMenu] = useState(false);
return(
    <div className='s-blog'>
          <div className='blog-img'>
            <img src='/image/class.png' alt='class' className='img-fluid' />
          </div>
          <div className='blog-desc'>
            <div className='blog-content'>
              <h6>Technology</h6>
              <h2>Using Total Talent Management To Embrace The Future Of Work</h2>
              <p>Virginia Poly</p>
              <h6>Dec 13, 2024</h6>
            </div>
            <div className='blog-extras'>
            <div className="ellipses-dd text-right">
              <button className="btn btn-transparent p-0" onClick={() => setShowMenu(!showMenu)}>
                <img src="/image/adJob-dd.png" alt="adJob-dd" />
              </button>
              <div className={showMenu ? 'cv-cions active' : 'cv-cions'}>
                {/* onClick={() => editJobClickHanlder(props.id)} */}
                <div className="dd-val">
                  <img src="/image/edit-black.png" alt="" className="show-tooltip" />
                  <p className="dd-content">Edit</p>
                </div>
                <div className="dd-val">
                    <img src="/image/delet-chos-res.png" alt="" className="show-tooltip" />
                    <p className="dd-content">Delete</p>
                </div>
              </div>
            </div>

              <label className="switch" for='check'>
                <input
                  type="checkbox"
                  // data-type={props.is_active === 0 ? false : true}
                  // checked={isActive}
                  // defaultChecked={props.is_active}
                  name='isActive'
                  // onChange={() => onSwitchChange(props)}
                  // id={`switch-${props.id}`}
                />
                <span className="visibility-hidden opacity-0 position-absolute">switch</span>
                <span className="slider ml-auto"></span>
              </label>
            </div>
          </div>
        </div>
)

}

export default BlogCard