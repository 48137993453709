import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RESUME_ITEM } from "../../../../constants";

const CanResumeTechSkillForm = ({
  prod,
  index,
  totalTechSkills,
  onTechSkillsChangeHandler,
  onTechSkillsSaveHandler,
  openDeleteItemHandler,
}) => {
  const uniqueId = Math.random();
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter technical skills"),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);
      onTechSkillsSaveHandler();

      const timer = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onCustomChangeHandler = async (e) => {
    const { name, value } = e?.target;
    await formik.setFieldValue(name, value);
    const newFormData = {
      ...formik?.values,
      [name]: value,
    };
    onTechSkillsChangeHandler(newFormData, index);
  };

  const getProData = async () => {
    await formik.setFieldValue("name", prod?.name || "");
  };

  useEffect(() => {
    formik?.resetForm();
    getProData();
  }, [totalTechSkills]);

  return (
    <div
      id={`collapseOne-${uniqueId}`}
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body educ-res-right">
        <div className="accordion" id={`sub-accordionExample-${uniqueId}`}>
          <div className="accordion-item experince-res-ver-right">
            <h2
              className="accordion-header sub-header"
              id={`sub-heading-${uniqueId}`}
            >
              <button
                className="accordion-button data-tab-res-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseanswer-pro-${uniqueId}`}
                aria-expanded="false"
                aria-controls={`collapse-${uniqueId}`}
              >
                <div className="job-title-resume-form">
                  <div className="compay-name-job-resume-right">
                    <p className="right-resume-cmp">
                      {" "}
                      {formik?.values?.name !== ""
                        ? formik?.values?.name
                        : "Type Technical Skills here"}
                    </p>
                  </div>
                  <div className="date-job-res m-0 gap-2">
                    <img
                      src="/image/edit-tab.png"
                      className="edit-res"
                      alt=""
                    />
                    <img
                      className="del-res"
                      src="/image/delet-res.png"
                      alt=""
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteItemHandler(
                          index,
                          RESUME_ITEM.TECHNICAL_SKILLS,
                          true
                        );
                      }}
                    />
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`sub-collapseanswer-pro-${uniqueId}`}
              className="accordion-collapse collapse"
              aria-labelledby={`sub-heading-${uniqueId}`}
              data-bs-parent={`#sub-accordionExample-${uniqueId}`}
            >
              <div className="accordion-body side-space">
                <h2
                  className="accordion-header sub-header"
                  id={`sub-heading-${uniqueId}`}
                >
                  <button
                    className="accordion-button resume-exper-right-tb"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-collapseOne"
                    aria-expanded="true"
                    aria-controls={`collapseOne-${uniqueId}`}
                  >
                    <form
                      onSubmit={formik.handleSubmit}
                      onKeyDown={handleKeyDown}
                    >
                      <div
                        className={`address-res f-feild-res ${
                          isFormFieldValid("name") && "error-input"
                        }`}
                      >
                        <textarea
                          className="feild-stl-res form-font-f"
                          cols="30"
                          rows="8"
                          name="name"
                          placeholder="Type here..."
                          value={formik?.values?.name}
                          onChange={onCustomChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("name")}
                      </div>
                    </form>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanResumeTechSkillForm;
