import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import APIPvtDB from "../../../../apiPvtD";
import { ADMIN } from "../../../../constants";
import "../ForgotForm/ForgotForm.css";
import Button from "react-bootstrap/Button";

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const { isEmailVerify, user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [passwordErrors, setPasswordErrors] = useState([]);
  const [oldPasswordShown, setoldPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please enter old password"),
      password: Yup.string().test((value) => {
        const errors = [];
        if (!value) {
          errors.push("Please enter new password");
        }
        if (!value || value.length < 8) {
          errors.push("Password must be at least 8 characters in length");
        }
        if (!/\d/.test(value)) {
          errors.push("Use a minimum of 1 numeric value");
        }
        if (!/[A-Z]/.test(value)) {
          errors.push("Use at least 1 upper case letter (ABCD)");
        }
        if (!/[a-z]/.test(value)) {
          errors.push("Use at least 1 lower case letter (abcd)");
        }
        if (!/[!#%$*@]/.test(value)) {
          errors.push("Use at least one special character (!# % $ * @)");
        }
        setPasswordErrors(errors);

        return errors.length === 0;
      }),
      confirmPassword: Yup.string()
        .required("Please re-enter confirm password")
        .oneOf([Yup.ref("password")], "Your passwords do not match"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { oldPassword, password, confirmPassword } = data;
      try {
        let params = `?`;
        if (oldPassword !== "") {
          params += `old_password=${oldPassword}&`;
        }
        if (password !== "") {
          params += `new_password=${password}&`;
        }
        if (confirmPassword !== "") {
          params += `new_password_confirmation=${confirmPassword}`;
        }
        setLoader(true);
        await APIPvtDB.post(`/auth/change-password${params}`)
          .then((response) => {
            if (response?.status === 200 || response?.status === 201) {
              const res = response?.data;
              if (res?.success === true) {
                if (isEmailVerify === true && ADMIN.includes(user?.user_type)) {
                  navigate("/employer-profile");
                } else {
                  navigate("/candidate-profile");
                }
                toast.success(res?.message, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              } else {
                toast.error(res?.message, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }
              setLoader(false);
            }
          })
          .catch((error) => {
            if (error) {
              const { response } = error;
              const { data } = response;
              setErrorMsg(data?.message);
              setTimeout(() => {
                setErrorMsg("");
              }, 5000);
              console.log(error);
            }
            setLoader(false);
          });
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isPasswordFieldValid = (passwordErrors) =>
    !!(formik.touched["password"] && passwordErrors?.length > 0);
  const getPasswordErrorMessage = (passwordErrors) => {
    if (isPasswordFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <>
      <section className="candiate-login">
        <div className="container container-site">
          <div className="candidate-login-row-password position-relative">
            <Button
              variant="link"
              className="back-to-home add"
              onClick={() => navigate(-1)}
            >
              <span className="visibility-hidden opacity-0 position-absolute">Go back</span>
              <img src="/image/form-back-arrow.png" alt="" />
            </Button>
            <div className="candiate-login-form">
              <div className="candidate-signin-password-ft change-password-f">
                <h2>Change password</h2>
                <form onSubmit={formik.handleSubmit}>
                  <div className="candidate-email">
                    <div
                      className={`signup-passowrd password-change-f ${isFormFieldValid("oldPassword") && "error-input"
                        }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={oldPasswordShown ? "text" : "password"}
                          name="oldPassword"
                          placeholder="Old password"
                          value={formik.values.oldPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        <div className="icon-hide">                         
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  oldPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setoldPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !oldPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setoldPasswordShown(false)}
                              />
                            </>                          
                        </div>
                      </div>
                      {getFormErrorMessage("oldPassword")}
                    </div>
                    <div
                      className={`signup-passowrd password-change-f ${isFormFieldValid("password") && "error-input"
                        }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          id="password-field"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Please enter new password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        <div className="icon-hide">                  
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !passwordShown && "password-icon-togle"
                                }
                                onClick={() => setPasswordShown(false)}
                              />
                            </>                        
                        </div>
                      </div>
                      {getPasswordErrorMessage(passwordErrors)}                     
                    </div>
                    <div
                      className={`signup-passowrd password-change-f ${isFormFieldValid("confirmPassword") && "error-input"
                        }`}
                    >
                      <div className="password-icon bg-pass-f">
                        <input
                          className="form-font-f"
                          type={confirmPasswordShown ? "text" : "password"}
                          name="confirmPassword"
                          id="password-field"
                          placeholder="Confirm password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        <div className="icon-hide">                        
                            <>
                              <img
                                src="/image/hide-password.png"
                                alt=""
                                className={
                                  confirmPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setConfirmPasswordShown(true)}
                              />
                              <img
                                src="/image/password.png"
                                alt=""
                                className={
                                  !confirmPasswordShown && "password-icon-togle"
                                }
                                onClick={() => setConfirmPasswordShown(false)}
                              />
                            </>                         
                        </div>
                      </div>
                      {getFormErrorMessage("confirmPassword")}
                    </div>
                    <div className="heading-below-p">
                      <p className="heading-below-text">{errorMsg}</p>
                    </div>
                    <div className="candidate-from-submit">
                      <button type="submit" className="password-btn">
                        {loader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Reset"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewPasswordForm;
