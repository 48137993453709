import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import CandidateForm from "./CandidateForm/CandidateForm";
import EmployerForm from "./EmployerForm/EmployerForm";
import API from "../../../api";
import ActionModal from "../../Common/ActionModal/ActionModal";
import {
  setActiveTab,
  setEmailVerify,
  setLoginUser,
  setLoginUserName,
  setLoginUserCountry,
  setUserResumes,
} from "../../../redux/slices/AuthSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import { ADMIN, CANDIDATE, EMPLOYER } from "../../../constants";
import { getResumeForApplyingOnJob } from "../../../redux/slices/candidateDetailSlice";
import { getUserTimezone } from "../../../utils/getUserTimezone";
import "./index.css";
import "./EmployerForm/EmployerForm.css";

const SignUpMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isEmailVerify } = useSelector((state) => state.auth);

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const status = searchParams.get("status");

  const { activeTab } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [toggleVModel, setToggleVModel] = useState(false);
  const [toggleModel, setToggleModel] = useState(false);

  const hideModel = (userType) => {
    const timeId = setTimeout(() => {
      if (CANDIDATE.includes(user?.user_type) || CANDIDATE.includes(userType)) {
        if (status === "true") navigate("/candidate-profile");
        else navigate("/candidate-dashboard");
      } else if (
        EMPLOYER.includes(user?.user_type) ||
        EMPLOYER.includes(userType)
      ) {
        if (status === "true") navigate("/employer-profile");
        else navigate("/employer-dashboard");
      } else if (ADMIN.includes(user?.user_type) || ADMIN.includes(userType)) {
        dispatch(
          setActiveSideBar({
            id: "2",
            name: "Department",
            value: "Department",
          })
        );
        navigate("/admin-department");
      }

      setErrorMsg("");
      setToggleModel(false);
      dispatch(setEmailVerify(true));
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  };

  const modelHandler = (value, userType) => {
    setToggleModel(value);
    if (value === true) hideModel(userType);
  };

  const toggleVModelHandler = (value) => {
    setToggleVModel(value);
    if (value === true) {
      setTimeout(() => {
        setToggleVModel(false);
      }, 5000);
    }
  };

  const errorMsgHandler = (msg) => {
    setErrorMsg(msg);
  };

  const CandidateSignUpHandler = async (data) => {
    const { firstName, lastName, email, password, confirmPassword } = data;
    try {
      // Get the user's timezone
      const userTimezone = getUserTimezone();

      const formData = new FormData();
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("email", email?.trim());
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("terms_of_services", 1);
      formData.append("timezone", userTimezone);

      setLoader(true);
      setErrorMsg("");

      await API.post("/auth/signup-candidate", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            if (res?.result === true) {
              localStorage.setItem("token", res?.access_token);
              toggleVModelHandler(true);
              setSuccessMsg(
                "To access the full functionality of our Job Portal, please verify your email address. This link is valid for 24 hours. If you did not request this, please ignore this message."
              );
            } else {
              let errorMsg = res?.message?.error;
              if (errorMsg) {
                toast.error(errorMsg, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toggleVModelHandler(false);
        });
    } catch (error) {
      setLoader(false);
      toggleVModelHandler(false);
      console.log(error);
    }
  };

  const employerSignUpHandler = async (data) => {
    const {
      firstName,
      lastName,
      email,
      companyName,
      industryId,
      companySizeId,
      password,
      confirmPassword,
    } = data;

    setErrorMsg("");

    try {
      // Get the user's timezone
      const userTimezone = getUserTimezone();

      const formData = new FormData();
      formData.append("first_name", firstName?.trim());
      formData.append("last_name", lastName?.trim());
      formData.append("email", email?.trim());
      formData.append("company_name", companyName);
      formData.append("industry_id", industryId);
      formData.append("company_size_id", companySizeId);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append(" terms_of_services", 1);
      formData.append("timezone", userTimezone);

      setLoader(true);
      setErrorMsg("");
      await API.post("/auth/signup", formData)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            const res = response?.data;
            if (res?.result === true) {
              localStorage.setItem("token", res?.access_token);
              toggleVModelHandler(true);
              setSuccessMsg(
                "To access the full functionality of our Job Portal, please verify your email address. This link is valid for 24 hours. If you did not request this, please ignore this message."
              );
            } else {
              let errorMsg = res?.message?.error;
              if (errorMsg) {
                toast.error(errorMsg, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toggleVModelHandler(false);
        });
    } catch (error) {
      setLoader(false);
      toggleVModelHandler(false);
      console.log(error);
    }
  };

  const getResumes = async (token) => {
    if (token) {
      dispatch(getResumeForApplyingOnJob({ token: token }))
        .then(async (response) => {
          const res = response?.payload?.data;
          dispatch(setUserResumes(res));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (code) {
      async function checkEmailVerify() {
        try {
          await API.get(`/auth/check-email-verified/${code}`)
            .then(async (response) => {
              if (response?.status === 200) {
                const res = response?.data;
                const { user } = response?.data;
                const { country } = user;
                if (res?.result === true) {
                  dispatch(
                    setLoginUser({ access_token: res?.token, user: user })
                  );
                  dispatch(setLoginUserName(user?.first_name));
                  dispatch(
                    setLoginUserCountry({
                      id: country?.id,
                      name: country?.name,
                    })
                  );
                  await getResumes(res?.token);
                  localStorage.setItem("token", res?.token);
                  if (res?.permissions)
                    dispatch(setdefaultPermissions(res?.permissions));

                  if (status === "true") {
                    if (CANDIDATE.includes(user?.user_type)) {
                      setSuccessMsg(
                        "Your account has been verified. Please upload your resume."
                      );
                    } else if (EMPLOYER.includes(user?.user_type)) {
                      setSuccessMsg("Your account has been verified.");
                    } else if (ADMIN.includes(user?.user_type)) {
                      setSuccessMsg("Your account has been verified.");
                    }
                  } else if (status === "false") {
                    setSuccessMsg("This email has already been verified.");
                  }

                  modelHandler(true, user?.user_type);
                }
              }
            })
            .catch((error) => {
              if (error) {
                console.log(error);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
      checkEmailVerify();
    }
  }, [code]);

  return (
    <>
      <section className="candiate-signup">
        <div className={"container container-site"}>
          <div className="candiate-signup-row">
            <div className="position-relative">
              <Button
                variant="link"
                className="back-to-home add resp-mob p-0"
                onClick={() => {
                  navigate("/");
                }}
              >
                <span className="visibility-hidden opacity-0 position-absolute">
                  back go
                </span>
                <img src="/image/form-back-arrow.png" alt="" />
              </Button>
              <div className="candidate-signup-form">
                <div className="candidate-signup">
                  <h2>
                    {activeTab === "candidate" && <>Create account</>}

                    {activeTab === "employer" && <>Create employer account</>}
                  </h2>
                  {isEmailVerify ? (
                    <></>
                  ) : (
                    <div className="login-here">
                      <p>
                        Already have account?
                        <Link to="/login" className="text-pacific-blue">
                          <span> Sign in</span>
                        </Link>
                      </p>
                    </div>
                  )}
                  <div className="candidate-employe-btn">
                    <Link
                      onClick={() => {
                        setSuccessMsg("");
                        dispatch(setActiveTab("candidate"));
                      }}
                    >
                      <button
                        className={
                          activeTab === "candidate"
                            ? "employee-btn toggle-btn-active"
                            : "employee-btn"
                        }
                      >
                        Candidate
                      </button>
                    </Link>
                    <Link
                      onClick={() => {
                        setSuccessMsg("");
                        dispatch(setActiveTab("employer"));
                      }}
                    >
                      <button
                        className={
                          activeTab === "employer"
                            ? "employee-btn toggle-btn-active"
                            : "employee-btn"
                        }
                      >
                        Employer
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              {activeTab === "candidate" ? (
                <>
                  <div className="candidate-signup-form">
                    <div className="or-passage d-none">
                      <p>Or</p>
                    </div>
                  </div>
                  <CandidateForm
                    loader={loader}
                    toggleVModel={toggleVModel}
                    errorMsgHandler={errorMsgHandler}
                    CandidateSignUpHandler={CandidateSignUpHandler}
                  />
                </>
              ) : (
                <EmployerForm
                  toggleVModel={toggleVModel}
                  employerSignUpHandler={employerSignUpHandler}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      {toggleVModel && (
        <ActionModal
          heading="Thank you for registering"
          content={successMsg}
          closeModal={() => modelHandler}
          modalArt="/image/success-modal.png"
        ></ActionModal>
      )}
      {toggleModel && (
        <ActionModal
          heading="Welcome to PolyCareers!"
          content={successMsg}
          closeModal={() => modelHandler}
          modalArt="/image/Congratulations.png"
        ></ActionModal>
      )}
    </>
  );
};

export default SignUpMain;
