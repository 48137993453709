import React, { Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import CanResumeSessionText from "../CanResumeSessionText/CanResumeSessionText";
import {
  candidateSummaryRegex,
  RESUME_SECTION_IDS,
  USER_TYPE,
} from "../../../../constants";
import "./CanResumeTempSTD.css";

const CanResumeTempSTD = ({
  enableAltText,
  userType,
  originalData,
  selectedSection,
  contactDetails,
  proExperience,
  education,
  certActSkills,
}) => {
  const sectionResumeSessionComponents = {
    [RESUME_SECTION_IDS.EDUCATION]: (section) => (
      <>
        {education?.length > 0 ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats std-heading">
              {" "}
              {section?.name || "Education"}
            </div>
            {education?.map((prod, index) => (
              <div key={index}>
                <div className="time-comp-res-exe">
                  {/* Institute Name */}
                  <div className="sub-heading-res-ats">
                    {prod?.institute != "" ? `${prod?.institute} - ` : ""}&nbsp;
                  </div>

                  {/* Date Range */}
                  {(prod?.start_date?.month || prod?.start_date?.year) && (
                    <div className="sub-heading-res-right-ats exe-right-weight">
                      {`${prod?.start_date?.month?.substring(0, 3)} ${
                        prod?.start_date?.year
                      }`}{" "}
                      {prod?.is_present
                        ? "- Present"
                        : prod?.end_date?.month || prod?.end_date?.year
                        ? `- ${prod?.end_date?.month?.substring(0, 3)} ${
                            prod?.end_date?.year
                          }`
                        : ""}
                    </div>
                  )}
                </div>

                {/* Degree */}
                {prod?.degree?.trim() && (
                  <div className="resume-job-list-std">
                    <ul>
                      <li>{prod?.degree}</li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats std-heading">
                {" "}
                {section?.name || "Education"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Education"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),

    [RESUME_SECTION_IDS.PROFESSIONAL_EXPERIENCE]: (section) => (
      <>
        {proExperience?.length > 0 ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats std-heading">
              {section?.name || "Professional Experience"}
            </div>
            {proExperience?.map((prod, index) => (
              <div key={index}>
                {/* Job Title & Company Info */}
                <div className="time-comp-res-exe">
                  {prod?.job_title && (
                    <div className="sub-heading-res-ats">
                      {`${prod?.job_title},`}&nbsp;
                    </div>
                  )}
                  {/* Company, City, Country */}
                  {(prod?.company_name || prod?.city || prod?.country) && (
                    <div className="sub-heading-res-right-ats exe-right-weight">
                      {prod?.company_name ? `${prod?.company_name}` : ""}
                      {prod?.city ? `, ${prod?.city}` : ""}
                      {prod?.country ? `, ${prod?.country}, ` : ""}
                    </div>
                  )}
                  &nbsp;
                  {/* Work Duration */}
                  {(prod?.start_date?.month || prod?.start_date?.year) && (
                    <div className="sub-heading-res-right-ats exe-right-weight">
                      {`${prod?.start_date?.month?.substring(0, 3)} ${
                        prod?.start_date?.year
                      }`}{" "}
                      {prod?.is_present
                        ? "- Present"
                        : prod?.end_date?.month || prod?.end_date?.year
                        ? `- ${prod?.end_date?.month?.substring(0, 3)} ${
                            prod?.end_date?.year
                          }`
                        : ""}
                    </div>
                  )}
                </div>

                {/* Responsibilities */}
                {prod?.roles_n_responsibilities?.trim() && (
                  <div className="resume-job-list-std">
                    {ReactHtmlParser(prod?.roles_n_responsibilities)}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats std-heading">
                {section?.name || "Professional Experience"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Professional Experience"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),

    [RESUME_SECTION_IDS.CERTIFICATES_ACTIVITIES_SKILLS]: (section) => (
      <>
        {certActSkills &&
        certActSkills?.replace(candidateSummaryRegex, "").trim() !== "" ? (
          <div className="resume-summar-exe">
            <div className="first-heading-res-ats std-heading">
              {section?.name || "Certificates/Activities/Skills"}
            </div>
            <div className="resume-job-list-std">
              {certActSkills?.replace(candidateSummaryRegex, "")?.trim() !==
                "" && ReactHtmlParser(certActSkills)}
            </div>
          </div>
        ) : (
          enableAltText && (
            <div className="resume-summar-exe">
              <div className="first-heading-res-ats std-heading">
                {section?.name || "Certificates/Activities/Skills"}
              </div>
              <CanResumeSessionText
                context={`Select the edit button to add ${
                  section?.name || "Certificates/Activities/Skills"
                }.`}
              />
            </div>
          )
        )}
      </>
    ),
  };

  return (
    <>
      <section className="resume-choose-template">
        <div className="container container-site">
          <div
            className={`resume-verivication-res ${
              userType === USER_TYPE.CANDIDATE &&
              "resume-verivication-res-border "
            }`}
          >
            <div className="resume-start-verivication">
              <div className="all-content-resume-verification">
                <div className="name-res-ats">
                  {`${contactDetails?.first_name ?? ""} ${
                    contactDetails?.last_name ?? ""
                  }`}
                </div>

                {contactDetails?.address?.trim() && (
                  <div className="res-std-location">
                    {contactDetails?.address}
                  </div>
                )}

                <div className="resume-info-std">
                  <div className="res-mail-std">
                    {[
                      contactDetails?.phone_number?.trim() && (
                        <span key="phone">
                          <a href="#">{contactDetails?.phone_number}</a>
                        </span>
                      ),
                      contactDetails?.email?.trim() && (
                        <span key="email">
                          <a href="#">{contactDetails?.email}</a>
                        </span>
                      ),
                    ]
                      .filter(Boolean)
                      .map((item, index, array) => (
                        <Fragment key={index}>
                          {item}
                          {index < array?.length - 1 && <span> | </span>}
                        </Fragment>
                      ))}
                  </div>
                  <div className="res-linkedin-std">
                    {contactDetails?.linkedin_url?.trim() && (
                      <span key="linkedin">{contactDetails?.linkedin_url}</span>
                    )}
                  </div>
                </div>

                <div className="resume-exe-bdr"></div>

                {/* Dynamic Resume Preview Sections */}

                {selectedSection
                  ?.sort((a, b) => a?.pivot?.order - b?.pivot?.order)
                  ?.map((section) =>
                    sectionResumeSessionComponents[section?.id]
                      ? sectionResumeSessionComponents[section?.id](section)
                      : null
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CanResumeTempSTD;
