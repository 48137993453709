import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import { getSearchSuggestionAsync } from "../../../../redux/slices/AuthSlice";
import { RESUME_ITEM } from "../../../../constants";
import "./CanResumeSkillForm.css";

const CanResumeSkillForm = ({
  prod,
  index,
  totalKeySkills,
  cateSkillsDropDown,
  onSkillsChangeHandler,
  onSkillsSaveHandler,
  openDeleteItemHandler,
}) => {
  const dispatch = useDispatch();

  const uniqueId = Math.random();

  const [loader, setLoader] = useState(false);
  const [allCateHide, setAllCateHide] = useState(false);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(false);

  const [allCateDropDown, setAllCateDropDown] = useState([]);
  const [allSkillDropDown, setAllSkillDropDown] = useState([]);

  const [selectedSkillTags, setSelectedSkillTags] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      skillName: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string(),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);
      onSkillsSaveHandler();

      const timer = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  /* Categories Event Handler */

  const filteredCateItem = allCateDropDown?.filter((item) =>
    item?.name?.toLowerCase().includes(formik?.values?.name?.toLowerCase())
  );

  const onCateChange = async (e) => {
    const { name, value } = e?.target;
    const updateCateSkill = { name: value, tags: selectedSkillTags };

    await formik.setFieldValue(name, value);
    onSkillsChangeHandler(updateCateSkill, index);
    setAllCateHide(false);
  };

  const onCateClick = async (curentCate) => {
    const updateCateSkill = { name: curentCate, tags: selectedSkillTags };

    await formik.setFieldValue("name", curentCate);
    onSkillsChangeHandler(updateCateSkill, index);
    setAllCateHide(true);
  };

  /********************/

  /* Skill Event Handler */

  const onSkillTagsChange = async (e) => {
    const { name, value } = e?.target;

    await formik.setFieldValue(name, value);
    getSearchSuggestion(encodeURIComponent(value), "jobTag");
  };

  const onSkillAdd = async (curentSkill) => {
    const enteredSkill = curentSkill?.trim()?.toLowerCase();
    const existingSkills = selectedSkillTags?.map((skill) =>
      skill?.name?.toLowerCase()
    );

    if (enteredSkill === "") {
      formik?.setFieldError("skillName", "Please Enter Skill");
      return;
    } else if (existingSkills?.includes(enteredSkill)) {
      formik?.setFieldError("skillName", "The Skill is already added");
      return;
    } else {
      const { name } = formik.values;

      const updateCateSkill = {
        name: name,
        tags: [...selectedSkillTags, curentSkill],
      };

      setSelectedSkillTags([...selectedSkillTags, curentSkill]);
      onSkillsChangeHandler(updateCateSkill, index);
      await formik.setFieldValue("skillName", "");
    }
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    // Trim the input and check if it's only numbers
    if (!isNaN(curentSkillTags?.trim())) return; // Prevent numeric-only tags

    const isNameExists = selectedSkillTags?.some(
      (item) => item?.toLowerCase() === curentSkillTags?.toLowerCase()
    );
    setAllSkillTagsHide(true);
    await formik.setFieldValue("skillName", "");
    if (isNameExists) return; // Prevent duplicates
    onSkillAdd(curentSkillTags);
  };

  const onSkillCancel = async (currentIndex) => {
    const { name } = formik.values;

    const updatedSkills = selectedSkillTags?.filter(
      (_, index) => index !== currentIndex
    );
    setSelectedSkillTags(updatedSkills);

    // Updating formik and calling handler to reflect changes
    const updateCateSkill = { name: name, tags: updatedSkills };
    onSkillsChangeHandler(updateCateSkill, index);
  };

  const handleSkillTagsKeyDown = (e) => {
    if (e?.key === "Enter" && e?.currentTarget?.value?.trim() !== "") {
      onSkillTagsClick(e?.currentTarget?.value?.trim()); // Add the custom tag
      e.preventDefault();
    }
  };

  /************************/

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      const { name } = formik.values;
      dispatch(
        getSearchSuggestionAsync({
          searchValue: searchValue,
          searchType: searchType,
          tagCategory: name,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.job_tags?.length > 0) {
            setAllSkillDropDown(res?.job_tags);
            setAllSkillTagsHide(false);
          } else {
            setAllSkillDropDown([]);
            setAllSkillTagsHide(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getProData = async () => {
    await formik.setFieldValue("name", prod?.name || "");

    if (cateSkillsDropDown?.length > 0) {
      setAllCateDropDown(cateSkillsDropDown);
    }

    if (prod?.tags?.length > 0) {
      setSelectedSkillTags(prod?.tags);
    } else {
      setSelectedSkillTags([]);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    getProData();
  }, [totalKeySkills]);

  return (
    <div
      id={`collapseOne-${uniqueId}`}
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body educ-res-right">
        <div className="accordion" id={`sub-accordionExample-${uniqueId}`}>
          <div className={`accordion-item experince-res-ver-right`}>
            <h2
              className="accordion-header sub-header"
              id={`sub-heading-${uniqueId}`}
            >
              <button
                className="accordion-button data-tab-res-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#sub-collapseanswer-cred-${uniqueId}`}
                aria-expanded="false"
                aria-controls={`collapse-${uniqueId}`}
              >
                <div className="job-title-resume-form">
                  <div className="compay-name-job-resume-right skill-resume-right">
                    <p className="right-resume-certificate">
                      {formik?.values?.name !== ""
                        ? `${formik?.values?.name}:`
                        : "Category:"}
                    </p>
                    {selectedSkillTags?.length > 0 ? (
                      <p className="right-resume-skill">
                        {selectedSkillTags.filter(Boolean).join(", ")}
                      </p>
                    ) : (
                      <p className="right-resume-skill">Skills</p>
                    )}
                  </div>
                  <div className="date-job-res skill-resume-left m-0 gap-2">
                    <img
                      src="/image/edit-tab.png"
                      className="edit-res"
                      alt=""
                    />
                    <img
                      className="del-res-skill"
                      src="/image/delet-res.png"
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        openDeleteItemHandler(index, RESUME_ITEM.SKILLS, true);
                      }}
                    />
                  </div>
                </div>
              </button>
            </h2>
            <div
              id={`sub-collapseanswer-cred-${uniqueId}`}
              className="accordion-collapse collapse"
              aria-labelledby={`sub-heading-${uniqueId}`}
              data-bs-parent={`#sub-accordionExample-${uniqueId}`}
            >
              <div className="accordion-body side-space">
                <h2
                  className="accordion-header sub-header"
                  id={`sub-heading-${uniqueId}`}
                >
                  <button
                    className="accordion-button resume-exper-right-tb"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sub-collapseOne"
                    aria-expanded="true"
                    aria-controls={`collapseOne-${uniqueId}`}
                  >
                    <form
                      onSubmit={formik.handleSubmit}
                      onKeyDown={handleKeyDown}
                    >
                      <div
                        className={`address-res f-feild-res tags-tittles ${
                          isFormFieldValid("name") && "error-input"
                        }`}
                      >
                        <label for="lname">Category</label>
                        <br />

                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setAllCateHide(true);
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter Category"
                            className="feild-stl-res form-font-f"
                            name="name"
                            value={formik?.values?.name}
                            onChange={(e) => {
                              onCateChange(e)                          
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </OutsideClickHandler>

                        {getFormErrorMessage("name")}

                        {allCateDropDown?.length > 0 &&
                          !filteredCateItem.length && (
                            <div
                              className={`job-tags-titles-dropdown ${
                                allCateHide && "job-dropdown-hide"
                              }`}
                            >
                              <ul className="dorpdown-list-location">
                                {allCateDropDown.map((j, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      onCateClick(j?.name);
                                    }}
                                  >
                                    {" "}
                                    {j?.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                        {filteredCateItem?.length > 0 && (
                          <div
                            className={`job-tags-titles-dropdown ${
                              allCateHide && "job-dropdown-hide"
                            }`}
                          >
                            <ul className="dorpdown-list-location">
                              {filteredCateItem.map((j, i) => (
                                <li
                                  key={i}
                                  onClick={() => {
                                    onCateClick(j?.name);
                                  }}
                                >
                                  {" "}
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div
                        className={`address-res f-feild-res tags-tittles ${
                          isFormFieldValid("skillName") && "error-input"
                        }`}
                      >
                        <label for="lname">Skills</label>
                        <br />

                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setAllSkillTagsHide(true);
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter Skills"
                            className="feild-stl-res form-font-f"
                            name="skillName"
                            value={formik?.values?.skillName}
                            onChange={(e) => {
                              onSkillTagsChange(e);                           
                            }}
                            onKeyDown={handleSkillTagsKeyDown}
                            onBlur={formik.handleBlur}
                          />
                        </OutsideClickHandler>

                        {getFormErrorMessage("skillName")}

                        {allSkillDropDown?.length > 0 && (
                          <div
                            className={`job-tags-titles-dropdown ${
                              allSkillTagsHide && "job-dropdown-hide"
                            }`}
                          >
                            <ul className="dorpdown-list-location">
                              {allSkillDropDown.map((j, i) => (
                                <li
                                  key={i}
                                  onClick={() => {
                                    onSkillTagsClick(j?.name);
                                  }}
                                >
                                  {" "}
                                  {j?.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {selectedSkillTags?.length > 0 && (
                          <div className="tags-skills">
                            <ul className="tags-titles-list gap-2">
                              {selectedSkillTags?.map((j, i) => (
                                <li key={i}>
                                  {j}
                                  <span onClick={() => onSkillCancel(i)}>
                                    <img src="../image/cross.png" alt="" />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </form>
                  </button>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanResumeSkillForm;
