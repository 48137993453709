import React from 'react'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'

const CreateBlog = () => {
  const navigation = useNavigate()
  return (
    <div className='page-space bg-clr-admin'>
      <div className="page-title-heading justify-content-start align-items-center mb-2">
        <img src='/image/form-back-arrow.png' className='me-2 cursor-pointer' onClick={() => navigation(-1)} />
        <h2 className='m-0'>Upload new blog</h2>
      </div>
      <div className='blog-upload-box'>
        <div className="page-title-heading flex-column justify-content-start mb-2">
          <h4 className='m-0'>Upload new blog</h4>
          <p className='opacity-50 mt-2'>Upload your blog details here</p>
        </div>
        <div className='form-group mb-3 mt-4'>
          <label>Title</label>
          <input className='form-control' placeholder='Enter title here' />
        </div>
        <div className='form-group mb-3'>
          <label>Excerpt (Title text for thumbnail)</label>
          <input className='form-control' placeholder='Write an excerpt here' />
        </div>
        <div className='form-group mb-3'>
          <label>Type</label>
          <select className='form-select'>
            <option>Type</option>
            <option value='post'>Post</option>
            <option value='page'>Page</option>
          </select>
        </div>
        <div className='form-group mb-3'>
          <label>Upload blog title image</label>
          <p className='fs-12 opacity-75'>Formats should be .jpeg, .png, .img</p>

          <div
            className={`candidate-res-file icon-cursor mt-3`}
            // onClick={() => modelHandler(true)}
          >
            <label for="file-input" id="candidate-res">
              <img src="/image/upload-icon-res.png" alt="pic" />
              <input id="file-input" type="text" />
              <p className="candidate-file-res-drop">Maximum file size should not exceed 10 MB </p>
              <button className='btn btn-outline-black'>Browse files</button>
            </label>
          </div>
        </div>
        <div className='form-group mb-3'>
          <label>Author name</label>
          <input className='form-control' placeholder='Enter name here' />
        </div>

        <div className='form-group mb-3'>
          <label>Author picture</label>
          <p className='fs-12 opacity-75'>Formats should be .jpeg, .png, .img</p>

          <div
            className={`candidate-res-file icon-cursor mt-3`}
            // onClick={() => modelHandler(true)}
          >
            <label for="file-input" id="candidate-res">
              <img src="/image/upload-icon-res.png" alt="pic" />
              <input id="file-input" type="text" />
              <p className="candidate-file-res-drop">Maximum file size should not exceed 10 MB </p>
              <button className='btn btn-outline-black'>Browse files</button>
            </label>
          </div>
        </div>

        <div className='form-group mb-3'>
          <label className='mb-2'>Blog description</label>
          <ReactQuill
            theme="snow"
            className={`job-des-copy-paste`}
            placeholder="Enter blog description here."
            name="JobDes"
          />
        </div>

        <div className='form-group mb-3'>
          <label>Related blogs link</label>
          <input className='form-control' placeholder='Enter link here' />
        </div>

        <div className='form-group mb-3'>
          <label>Related blogs link</label>
          <input className='form-control' placeholder='Enter link here' />
        </div>
        <div className='form-group mb-3'>
          <label>Related blogs link</label>
          <input className='form-control' placeholder='Enter link here' />
        </div>
      </div>
      <div className='btn-stages'>
            <button className='btn btn-outline ms-2' >Post later</button>
            <button className='btn btn-black ms-2' >Post now</button>
          </div>
    </div>
  )
}

export default CreateBlog