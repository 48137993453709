import "./CanResumeMenu.css";

const CanResumeMenu = ({
  resumeCategories,
  selectedCategory,
  categoryTemplates,
  selectedTemplate,
  onCategoryChange,
  onTemplateChange,
}) => {
  return (
    <>
      <section className="resume-choose-template-sec">
        <div className="container container-site">
          <div className="resume-type-template-row">
            <div className="resume-template-type">
              {resumeCategories?.length > 0 ? (
                resumeCategories?.map((category) => (
                  <div
                    key={category?.id}
                    className={`res-type-name ${
                      selectedCategory?.id === category?.id
                        ? "active-resume-template"
                        : ""
                    }`}
                    onClick={() => onCategoryChange(category)}
                  >
                    <a href="#">{category?.name}</a>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="resume-choose-template-image">
        <div className="container container-site">
          <div className="template-res-type-content">
            At PolyCareers, we provide resume templates to help you effectively
            showcase your skills, experience, and achievements. Choose the
            template that best fits your background and use it to apply with
            confidence!
          </div>
          <div className="template-resume-sample">
            {categoryTemplates?.length > 0 ? (
              categoryTemplates?.map((template) => (
                <div
                  key={template?.id}
                  className={`first-sample ${
                    selectedTemplate?.id === template?.id
                      ? "active-sample-res"
                      : ""
                  }`}
                >
                  <img
                    src={
                      template?.icon_path
                        ? template?.icon_path
                        : "/image/resume-template/default.png"
                    }
                    alt={template?.name}
                    onClick={() => onTemplateChange(template)}
                  />
                  <p className="type-sample-res">{template?.name}</p>
                  <p className="price-sample-res">
                    {template?.is_paid
                      ? template?.is_purchased === 1
                        ? `Purchased `
                        : `$${template?.price}`
                      : "Free"}
                  </p>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CanResumeMenu;
