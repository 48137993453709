import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import "./CanResumekeyCompForm.css";

const CanResumekeyCompForm = ({
  keyCompetencies,
  onkeyCompAddHandler,
  onkeyCompDeleteHandler,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string(),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);

      const timer = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onKeyCompAdd = async (curentKeyComp) => {
    const enteredKeyComp = curentKeyComp?.name?.trim()?.toLowerCase();
    const existingKeyComp = keyCompetencies?.map((keyComp) =>
      keyComp?.name?.toLowerCase()
    );

    if (enteredKeyComp === "") {
      formik?.setFieldError("name", "Please enter key competencies");
      return;
    } else if (existingKeyComp?.includes(enteredKeyComp)) {
      formik?.setFieldError("name", "The key competencies is already added");
      return;
    } else {
      onkeyCompAddHandler(curentKeyComp);
      await formik.setFieldValue("name", "");
    }
  };

  const onKeyCompChange = async (e) => {
    const { name, value } = e?.target;
    await formik.setFieldValue(name, value);
  };

  const onKeyCompClick = async (curentKeyComp) => {
    // Trim the input and check if it's only numbers
    if (!isNaN(curentKeyComp?.trim())) return; // Prevent numeric-only key Comp

    const newKeyComp = { name: curentKeyComp };
    const isNameExists = keyCompetencies?.some(
      (item) => item?.name?.toLowerCase() === curentKeyComp?.toLowerCase()
    );
    await formik.setFieldValue("name", "");
    if (isNameExists) return; // Prevent duplicates
    onKeyCompAdd(newKeyComp);
  };

  const onKeyCompCancel = async (currentIndex) => {
    onkeyCompDeleteHandler(currentIndex);
  };

  const handleKeyCompKeyDown = (e) => {
    if (e?.key === "Enter" && e?.currentTarget?.value?.trim() !== "") {
      onKeyCompClick(e?.currentTarget?.value?.trim()); 
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {   
    const { name } = formik.values;   
    if (name?.trim() !== "") {
      onKeyCompClick(name?.trim()); 
      e.preventDefault();
    }
  };

  const getKeyCompData = async () => {
    await formik.setFieldValue("name", "");
  };

  useEffect(() => {
    formik?.resetForm();
    getKeyCompData();
  }, []);

  return (
    <div className="accordion-item experince-res-ver-right-skills">
      <button
        className="accordion-button resume-skills-right-tb"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sub-collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <form onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
          <div
            className={`address-res f-feild-res tags-tittles ${
              isFormFieldValid("name") && "error-input"
            }`}
          >
            <label for="lname">Key Competencies</label>
            <br />

            <input
              type="text"
              placeholder="Key Competencies"
              className="feild-stl-res form-font-f"
              name="name"
              value={formik?.values?.name}
              onChange={(e) => {
                onKeyCompChange(e);
                formik.handleChange(e);
              }}
              onKeyDown={handleKeyCompKeyDown}
              onBlur={formik.handleBlur}
            />

            {getFormErrorMessage("name")}

            {keyCompetencies?.length > 0 && (
              <div className="tags-skills">
                <ul className="tags-titles-list gap-2">
                  {keyCompetencies.map((j, i) => (
                    <li key={i}>
                      {j?.name}
                      <span onClick={() => onKeyCompCancel(i)}>
                        <img src="../image/cross.png" alt="" />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="address-res f-feild-res">
            <div className="two-can-sav-btn">
              <button
                className="save-res-btn icon-cursor"
                type="button"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </button>
    </div>
  );
};

export default CanResumekeyCompForm;
