import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./CanDetailResume.css";
import { EmptyRecord } from "../../../../constants";

const CanDetailResume = ({
  resumeData,
  contactDetails,
  experience,
  projects,
  education,
  certifications,
  keySkills,
  handlePreviewResume,
}) => {

  return (
    <div className="tab-content ex1-content" id="ex1-content">
      <div
        className="tab-pane fade show active"
        id="ex1-tabs-1"
        role="tabpanel"
        aria-labelledby="ex1-tab-1"
      >
        <div className="tabs-content-jour">
          <div className="text-resume-buttn-j">
            <p className="content-heading-jour-res">Resume</p>
            <div className="resume-dwl-pr-btn">
              <button
                className="resum-btn-p"
                onClick={() =>
                  handlePreviewResume()
                }
              >
                PDF preview           
              </button>
            </div>
          </div>
          <div className="resume-start-jour">
            <div className="all-content-resume-jour">
              <div className="name-res-jou">{`${
                !EmptyRecord.includes(contactDetails?.firstName) 
                  ? contactDetails?.firstName
                  : ""
              } ${
                !EmptyRecord.includes(contactDetails?.lastName)        
                  ? contactDetails?.lastName
                  : ""
              }`}</div>

              {contactDetails?.address !== null &&
                contactDetails?.address !== "" && (
                  <div className="location-res-j">    
                    <p>{contactDetails?.address}</p>
                  </div>
                )}

              <div className="resume-can-info">
                {contactDetails?.email !== "" && (
                  <div className="box-one-res">      
                    <span>Email:</span>              
                    <a href="#">{contactDetails?.email}</a>
                  </div>
                )}

                {contactDetails?.phone_number !== "" && (
                  <div className="box-two-res">    
                    <span>Phone:</span>         
                    <a href="#">{contactDetails?.phone_number}</a>
                  </div>
                )}               
              </div>
              <div className="resume-content-start">
                {contactDetails?.candidate_summary !== "" && (
                  <>
                    <div className="first-heading-res-j">Summary</div>
                    <p className="res-sumry-jour">
                      {contactDetails?.candidate_summary}
                    </p>
                  </>
                )}

                {experience?.length > 0 && (
                  <>
                    <div className="first-heading-res-j">
                      Professional Experience
                    </div>
                    {experience.map((prod, index) => (
                      <>
                        <div className="time-comp-res-jour">
                          <div className="sub-heading-res-j">
                            {prod?.company_name !== ""
                              ? prod?.company_name
                              : "Employer"}
                            {prod?.city !== ""
                            ? `, ${prod?.city}`
                            : ""}
                             {prod?.country !== ""
                            ? `, ${prod?.country}`
                            : ""}
                          </div>
                          <div className="sub-heading-res-j-right">
                            {prod?.start_date?.month === "" &&
                            prod?.start_date?.year === ""
                              ? ""
                              : `${prod?.start_date?.month?.substring(0, 3)} ${
                                  prod?.start_date?.year
                                }`}{" "}
                            {prod?.is_present
                              ? "- Present"
                              : prod?.end_date?.month === "" &&
                                prod?.end_date?.year === ""
                              ? ""
                              : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                                  prod?.end_date?.year
                                }`}
                          </div>
                        </div>
                        <p className="res-postion-j">
                          Position:{" "}
                          <span>
                            {" "}
                            {prod?.job_title !== ""
                              ? prod?.job_title
                              : "Job Title"}                        
                          </span>{" "}
                        </p>
                        <div className="cus-resume-job-list">
                          {prod?.roles_n_responsibilities !== "" &&
                            ReactHtmlParser(prod?.roles_n_responsibilities)}
                        </div>
                      </>
                    ))}
                  </>
                )}

                {projects?.length > 0 && (
                  <>
                    <div className="projects-resu">
                      <div className="first-heading-res-j">Projects</div>
                      {projects.map((prod, index) => (
                        <>
                          <div className="time-comp-res-jour">
                            <div className="sub-heading-res-j">
                              {prod?.name !== "" ? prod?.name : "Project Name"}
                            </div>
                          </div>
                          <div className="project-comp-res">
                            {prod?.url !== "" && (
                              <div className="sub-heading-res-j">
                                Project URL:{" "}
                                <a
                                  target="_blank"
                                  href={prod?.url !== "" ? prod?.url : "#"}
                                  className="project-link-res-j"
                                >
                                  {prod?.url !== "" ? prod?.url : ""}
                                </a>{" "}
                              </div>
                            )}
                          </div>

                          {prod?.description && prod?.description !== "" && (
                            <ul className="resume-job-list">
                              <li>{prod?.description} </li>
                            </ul>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                )}

                {education?.length > 0 && (
                  <>
                    <div className="education-resu">
                      <div className="first-heading-res-j">Education</div>
                      {education.map((prod, index) => (
                        <>
                          <div className="time-comp-res-jour">
                            <div className="sub-heading-res-j">
                              {prod?.institute !== ""
                                ? prod?.institute
                                : "University/Institute Name"}
                            </div>
                            <div className="sub-heading-res-j-right">
                              {" "}
                              {prod?.start_date?.month === "" &&
                              prod?.start_date?.year === ""
                                ? ""
                                : `${prod?.start_date?.month?.substring(
                                    0,
                                    3
                                  )} ${prod?.start_date?.year}`}{" "}
                              {prod?.is_present
                                ? "- Present"
                                : prod?.end_date?.month === "" &&
                                  prod?.end_date?.year === ""
                                ? ""
                                : `- ${prod?.end_date?.month?.substring(
                                    0,
                                    3
                                  )} ${prod?.end_date?.year}`}
                            </div>
                          </div>
                          <p className="edu-resume">
                            {" "}
                            {prod?.degree !== "" ? prod?.degree : "Degree"}
                          </p>
                        </>
                      ))}
                    </div>
                  </>
                )}

                {certifications?.length > 0 && (
                  <>
                    <div className="certificat-resume">
                      <div className="first-heading-res-j">
                        Certifications
                      </div>
                      {certifications.map((prod, index) => (
                        <div className="certification-res-jour">
                          <ul className="certificate-res-list">
                            <li>
                              {" "}
                              {prod?.name !== ""
                                ? prod?.name
                                : "Certification Name"}
                            </li>
                          </ul>
                          <div className="sub-heading-res-j-right">
                            {" "}
                            {prod?.start_date?.month === "" &&
                            prod?.start_date?.year === ""
                              ? ""
                              : `${prod?.start_date?.month?.substring(0, 3)} ${
                                  prod?.start_date?.year
                                }`}{" "}
                            {prod?.end_date?.month === "" &&
                            prod?.end_date?.year === ""
                              ? ""
                              : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                                  prod?.end_date?.year
                                }`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {keySkills?.length > 0 && (
                  <>
                    <div className="first-heading-res-j">Skills</div>
                    <ul className="resume-job-list">
                      <li>
                        {keySkills.map(
                          (prod, index) =>
                            prod?.name !== "" && `${prod?.name}, `
                        )}
                      </li>
                    </ul>
                  </>
                )}

                <div className="first-heading-res-j">References</div>
                <ul className="resume-job-list">
                  <li>Available on request</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mbl-resume-all">
            <div className="resume-name-mbl">
              <a href="#" target="_blank">
                {resumeData?.fullName !== "" && resumeData?.fullName}{" "}
                {/* <span>( Senior React Developer )</span> */}
              </a>
              <img
                className="preview-icon-job"
                src="/image/mbl-cv-icon.png"
                alt=""
                onClick={() =>
                  handlePreviewResume()
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanDetailResume;
