// AdminSidebar.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import {
  clearLoginUser,
  setResumeDetail,
} from "../../../redux/slices/AuthSlice";
import { setInboxUser } from "../../../redux/slices/InboxSlice";
import { setTotalBulkResumeCredit } from "../../../redux/slices/buyBulkAnalysisSlice";
import { ADMIN_SIDE_BAR, USER_TYPE } from "../../../constants";
import "./AdminSidebar.css";

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [isExpanded, setIsExpanded] = useState(true);

  const { activeSideBar, defaultPermissions } = useSelector(
    (state) => state.adminDashboard
  );

  const [adminSideBarOption, setAdminSideBarOption] = useState([
    {
      id: "10",
      name: "Companies",
      value: "Companies",
      path: "/companies",
      subMenuOpen: false,
      icon: "/image/menuIcon/companies.png",
      key: "companies_view",
      subMenuOption: [],
    },
    {
      id: "11",
      name: "Candidates",
      value: "Candidates",
      path: "/candidates",
      subMenuOpen: false,
      icon: "/image/menuIcon/users.png",
      key: "admin_candidates_view",
      subMenuOption: [],
    },
    {
      id: "12",
      name: "Jobs",
      value: "Jobs",
      path: "/jobs",
      subMenuOpen: false,
      icon: "/image/briefcase-sidebar.png",
      key: "jobs_view",
      subMenuOption: [],
    },
    {
      id: "13",
      name: "Location",
      value: "Location",
      path: "/location",
      subMenuOpen: false,
      icon: "/image/menuIcon/location.png",
      key: "location_view",
      subMenuOption: [],
    },
    {
      id: "2",
      name: "Team setup",
      value: "Team setup",
      path: "/admin-department",
      subMenuOpen: false,
      icon: "/image/users-sidebar.png",
      key: "system_setup_view",
      subMenuOption: [
        {
          id: "23",
          name: "Department",
          value: "Department",
          path: "/admin-department",
          subMenuOpen: false,
          key: ["department_view","admin_departments_view"],
        },
        {
          id: "24",
          name: "Roles",
          value: "Roles",
          path: "/admin-role",
          subMenuOpen: false,
          key: ["roles_view","admin_roles_view"],
        },
        {
          id: "25",
          name: "Users",
          value: "Users",
          path: "/admin-user",
          subMenuOpen: false,
          key: ["users_view","admin_users_view"],
        },
      ],
    },
    {
      id: "6",
      name: "My job posts & more",
      value: "My job posts & more",
      path: "/jobs-posted",
      subMenuOpen: false,
      icon: "/image/briefcase-sidebar.png",
      key: "job_posted_view",
      subMenuOption: [
        {
          id: "6",
          name: "View, edit your job posts, hiring process & prescreening questions.",
          value:
            "My job posts & more",
          path: "/jobs-posted",
          subMenuOpen: false,
          key: "job_posted_view",
        },
      ],
    },
    {
      id: "3",
      name: "PolyCareers store",
      value: "PolyCareers store",
      path: "/admin-all-promotion",
      subMenuOpen: false,
      icon: "/image/store-sidebar.png",
      key: "polycareers_store",
      subMenuOption: [
        {
          id: "32",
          name: "All promotions",
          value: "All promotions",
          path: "/admin-all-promotion",
          subMenuOpen: false,
          key: "promotions_view",
        },
        {
          id: "33",
          name: "Buy more posts",
          value: "buy more posts",
          path: "/buy-more-posts",
          subMenuOpen: false,
          key: "buy_more_post",
        },
        {
          id: "35",
          name: "Promote post",
          value: "Promote post",
          path: "/admin-promotion-job",
          subMenuOpen: false,
          key: "promotions_create",
        },
        {
          id: "36",
          name: "Promote company",
          value: "Promote company",
          path: "/admin-promotion-company",
          subMenuOpen: false,
          key: "promotions_create",
        },
        {
          id: "34",
          name: "Promotions requests",
          value: "Promotions requests",
          path: "/admin-promotion-req",
          subMenuOpen: false,
          key: "promotion_requests_view",
        },
      ],
    },
    {
      id: "4",
      name: "Bulk resume analysis",
      value: "Bulk resume analysis",
      path: "/bulk-resume-analysis",
      subMenuOpen: false,
      icon: "/image/analysice-sidebar.png",
      key: "bulk_resume_view",
      subMenuOption: [
        {
          id: "40",
          name: "Buy PolyCareers credits",
          value: "Buy PolyCareers credits",
          path: "/buy-bulk-analysis",
          subMenuOpen: false,
          key: "buy-bulk-analysis",
        },       
        {
          id: "41",
          name: "Resume batch tool",
          value: "Resume batch tool",
          path: "/bulk-resume-analysis",
          subMenuOpen: false,
          key: "bulk_resume_view",
        }
      ],
    },
    // if key exsist with value 0 so then it will show else not
    {
      id: "9",
      name: "Blogs",
      value: "Blogs",
      path: "/blog-listing",
      subMenuOpen: false,
      icon: "/image/blog-icon.png",
      key: "blogs",
      subMenuOption: [
        {
          id: "49",
          name: "Blog posting",
          value: "Blog posting",
          path: "/blog-listing",
          subMenuOpen: false,
          key: "blogs_view",
        }
      ],
    },
  ]);

  /***********Side Bar Event**************/

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleSubMenu = (index) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index]?.id,
        name: updatedAdminSideBarOption[index]?.name,
        value: updatedAdminSideBarOption[index]?.value,
      })
    );
  };

  const toggleSubSubMenu = (index, subIndex) => {
    const updatedAdminSideBarOption = [...adminSideBarOption];
    dispatch(
      setActiveSideBar({
        id: updatedAdminSideBarOption[index]?.id,
        name: updatedAdminSideBarOption[index]?.subMenuOption[subIndex]?.name,
        value: updatedAdminSideBarOption[index]?.subMenuOption[subIndex]?.value,
      })
    );
  };

  const filterSubMenuOptions = (menuItems, permission) => {
    return menuItems
      .map(({ subMenuOption, key, ...rest }) => {
        // ✅ Recursively filter submenu options
        const filteredSubMenu = subMenuOption?.length
          ? filterSubMenuOptions(subMenuOption, permission)
          : [];
  
        // ✅ Skip item if permission exists & is set to 0
        if (permission?.[key] === 0) return null;
  
        console.log(permission?.hasOwnProperty(key), key, permission);
  
        return { ...rest, key, subMenuOption: filteredSubMenu };
      })
      .filter(Boolean); // ✅ Remove null values
  };
  

  /*************************/

  const onSystemHubHandler = () => {
    if (user?.user_type !== USER_TYPE.SUPER_ADMIN){
      dispatch(
        setActiveSideBar({
          id: "6",
          name: "My job posts & more",
          value: "My job posts & more",
        })
      );
      navigate("/jobs-posted");
    }
    }
   
  const adminLogoClick = () => {
    if (user?.user_type !== USER_TYPE.SUPER_ADMIN) {
      navigate("/");
    } else {
      dispatch(
        setActiveSideBar({
          id: "2",
          name: "Department",
          value: "Department",
        })
      );
      navigate("/admin-department");
    }
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "2",
        name: "Department",
        value: "Department",
      })
    );
    dispatch(setInboxUser({}));
    dispatch(setTotalBulkResumeCredit(0));
    localStorage.removeItem("token");
  };

  useEffect(() => {
    if (user?.user_type === USER_TYPE.SUPER_ADMIN) {
      const nonAdminKeys = ['My job posts & more', 'PolyCareers store', 'Bulk resume analysis']
      const superAdminMenu = adminSideBarOption.filter((menu) => !nonAdminKeys.includes(menu?.name));
      const updatedSidebar = filterMenu(superAdminMenu)
      setAdminSideBarOption(updatedSidebar);
    } else {
      const adminKeys = ['Companies', 'Candidates', 'Jobs', 'Location', 'Blogs']
      const superAdminMenu = adminSideBarOption.filter((menu) => !adminKeys.includes(menu?.name));
      const updatedSidebar = filterMenu(superAdminMenu)
      setAdminSideBarOption(updatedSidebar);
    }
  }, [defaultPermissions]);

  const filterMenu = (menu) => {
    const updatedMenu = menu?.map((topLevelItem) => {
      if (topLevelItem?.subMenuOption) {
        topLevelItem.subMenuOption = filterSubMenuOptions(
          topLevelItem?.subMenuOption,
          defaultPermissions
        );
      }
      return topLevelItem;
    });
    return updatedMenu
  }

  return (
    <>
      <aside id="sidebar" className={isExpanded ? "expand" : ""}>
        <div className="d-flex">
          {isExpanded ? (
            <a href="/">
            <div
              className="siebar-logo icon-cursor"
              onClick={() => adminLogoClick()}
            >
              <img
                src="/image/PolyCareers.png"
                alt="PolyCareers"
                onClick={toggleSidebar}
              />
            </div>
            </a>
          ) : (
            <a href="/">
            <button
              className="toggle-btn"
              type="button"
              onClick={toggleSidebar}
            >
              <img src="/image/pc-logo-m.png" alt="" />
            </button>
            </a>
          )}
        </div>
        <div className="sidebar-clos-icon">
          <img src="/image/left-sidebar.png" alt="" onClick={toggleSidebar} />
        </div>
        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={()=> onSystemHubHandler()}>
              <img src="image/system-hub.png" alt="" />
              <span>
                {
                  user.user_type === 'superadmin' ? 'Super admin' : 'System hub' 
                }
              </span>
            </a>
          </li>
          {adminSideBarOption?.map((option, index) => (
            <li className="sidebar-item" key={index}>
              <Link
                to={option?.path}
                className="sidebar-link"
                onClick={() => toggleSubMenu(index)}
              >
                <img src={option?.icon} alt="" />
                <span>{option?.name}</span>
              </Link>

              {option?.subMenuOption?.length > 0 && (
                <ul id="auth" className="sidebar-dropdown list-unstyled">
                  {option?.subMenuOption?.map((subOption, subIndex) => (
                    <li
                      className={`submenubar-item-sidebar ${
                        subOption?.id === activeSideBar?.id ||
                        subOption?.name === activeSideBar?.name ||
                        subOption?.value === activeSideBar?.value
                          ? "active-menu-sidebar"
                          : ""
                      }`}
                      key={subIndex}
                    >
                      <Link
                        to={subOption?.path}
                        className="sidebar-link"
                        onClick={() => toggleSubSubMenu(index, subIndex)}
                      >
                        <span
                          className={` ${
                            subOption?.id === "41" || subOption?.id === "32"
                              ? "active-menu-imp"
                              : ""
                          }`}
                        >
                          {subOption?.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
              {
                console.log(adminSideBarOption)
              }
          <li className="sidebar-item">
            <Link
              to="/"
              className="sidebar-link"
              onClick={() => onLogOutHandler()}
            >
              <img src="/image/logout-sidebar.png" alt="" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminSidebar;
