import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CanDetailTitle from "../../../components/Employer/CandidateDetail/CanDetailTitle/CanDetailTitle";
import CanDetailHead from "../../../components/Employer/CandidateDetail/CanDetailHead/CanDetailHead";
import CanDetailTab from "../../../components/Employer/CandidateDetail/CanDetailTab/CanDetailTab";

/* Resume Template*/
import CanResumeTemp from "../../../components/Candidate/Resume/CanResumeTemp/CanResumeTemp";
import CanResumeTempATS from "../../../components/Candidate/Resume/CanResumeTempATS/CanResumeTempATS";
import CanResumeTempEXE from "../../../components/Candidate/Resume/CanResumeTempEXE/CanResumeTempEXE";
import CanResumeTempSTD from "../../../components/Candidate/Resume/CanResumeTempSTD/CanResumeTempSTD";

/*************/
import CanDetailEval from "../../../components/Employer/CandidateDetail/CanDetailEval/CanDetailEval";
import CanDetailActivities from "../../../components/Employer/CandidateDetail/CanDetailActivities/CanDetailActivities";
import {
  getCandidateDetailAsync,
  getParsedResumeAsync,
  setCandidateDetailTab,
} from "../../../redux/slices/candidateDetailSlice";
import {
  candidateFirstImpressionChangeAsync,
  favoriteCandidateAsync,
} from "../../../redux/slices/employerDashboardSlice";
import {
  CANDIDATE_Detail_Tab,
  CANDIDATE_STATUSES,
  RESUME_SECTION_IDS,
  RESUME_TEMPLATE_IDS,
  USER_TYPE,
} from "../../../constants";
import "./EmpCandidateDetail.css";
import CandidatesNotesSec from "../../../components/Employer/CandidateDetail/CandidatesNotesSec/CandidatesNotesSec";
import { generateSignedURLDataAsync } from "../../../redux/slices/myResumeSlice";

const EmpCandidateDetail = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {
    candidateDetailTab,
    candidateDetailId,
    candidateDetailResumeId,
    jobDetailId,
  } = useSelector((state) => state.candidateDetail);

  /* Candidate Detail on Resume Session */

  const [originalData, setOriginalData] = useState({
    fullName: "",
    originalResumeURL: null,
  });

  /* Resume Templates */
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  /* Resume Session */
  const [contactDetails, setContactDetails] = useState({});
  const [proExperience, setProExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [education, setEducation] = useState([]);

  /* Resume Default Session */
  const [certifications, setCertifications] = useState([]);
  const [keySkills, setKeySkills] = useState([]);

  /* Resume ATS Friendly Session */
  const [keyCompetencies, setkeyCompetencies] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [techSkills, setTechSkills] = useState([]);

  /* Resume Executive Session */
  const [awardRecogn, setAwardRecogn] = useState("");

  /* Resume Student Session */
  const [certActSkills, setCertActSkills] = useState("");

  const [userTabs, setUserTabs] = useState(false);

  /**************************************/

  /* Candidate Detail + Candidate Evaluation */
  const [loader, setLoader] = useState(false);

  const [jobDetailData, setJobDetailData] = useState({});
  const [candidateDetailData, setCandidateDetailData] = useState({});
  const [candidateData, setCandidateData] = useState({
    analysis_skills: {},
    avatarLink: null,
    isFavorite: 0,
    currentCompany: "",
    currentJobTitle: "",
    email: "",
    education: null,
    linkedinURL: "",
    workPreferences: [],
    techTag: [],
  });

  /* Candidate Detail on first impression click */
  const onFirstImpressionClick = async (
    candidateId,
    jobId,
    impressionId,
    currentStatusId
  ) => {
    if (currentStatusId === CANDIDATE_STATUSES.NEW_APPLICANT) return;
    if (candidateId === null || jobId === null || impressionId === null) return;

    if (parseInt(candidateData?.isFavorite) === 1 && impressionId === 1) {
      toast.error("Favourite applicant cannot have not a good fit status", {
        autoClose: 2000,
        closeOnClick: false,
      });
      return;
    }
    dispatch(
      candidateFirstImpressionChangeAsync({
        candidateId: candidateId,
        jobId: jobId,
        impressionId: impressionId,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          getEmpCandidatesDetailData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Candidate Detail on Favourite click */
  const onFvtClick = async (account_id, job_id) => {
    if (candidateDetailData?.impression_id === 1) {
      toast.error(
        "Not a Good Fit Applicant cannot be added to Favourite list.",
        {
          autoClose: 2000,
          closeOnClick: false,
        }
      );
      return;
    }
    dispatch(
      favoriteCandidateAsync({
        userId: user?.id,
        accountId: account_id,
        jobId: job_id,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setCandidateData({
            ...candidateData,
            isFavorite: candidateData?.isFavorite === 0 ? 1 : 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* PDF Preview Handler */

  const handlePreviewResume = () => {
    if (candidateDetailData) {
      const { account_id, application_id, resume_id } = candidateDetailData;
      dispatch(
        generateSignedURLDataAsync({
          candidate_id: account_id,
          application_id: application_id,
          resume_id: resume_id,
          file_type: "pdf",
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          if (res?.result === true) {
            const { url } = res;
            if (url !== null) {
              const pdfUrl = url;
              window.open(pdfUrl, "_blank");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* Candidate Detail on Resume Session */

  const getParsedResumeData = async () => {
    if (candidateDetailId !== null) {
      dispatch(
        getParsedResumeAsync({
          userId: candidateDetailId,
          resumeId: candidateDetailResumeId,
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          const { latest_resume } = res;
          const { template } = res;
          const {
            contact_details,
            key_competencies,
            experience,
            projects,
            education,
            certificates,
            certifications,
            key_skills,
            technical_skills,
            awards_recognitions,
            certificates_activities_skills,
            parsing_status,
            parsing_message,
          } = res?.resume_data;

          setContactDetails({
            address: contact_details?.address || "",
            candidate_summary:
              typeof contact_details?.candidate_summary === "string"
                ? contact_details.candidate_summary
                : "",
            email: contact_details?.email || "",
            first_name: contact_details?.first_name || "",
            github_url: contact_details?.github_url || "",
            last_name: contact_details?.last_name || "",
            linkedin_url: contact_details?.linkedin_url || "",
            name: contact_details?.name || "",
            phone_number: contact_details?.phone_number || "",
            website: contact_details?.website || "",
          });

          setkeyCompetencies(
            Array.isArray(key_competencies) ? key_competencies : []
          );

          setProExperience(Array.isArray(experience) ? experience : []);

          setProjects(Array.isArray(projects) ? projects : []);

          setEducation(Array.isArray(education) ? education : []);

          setCertificates(
            Array.isArray(certificates) ? certificates : []
          );

          setCertifications(
            Array.isArray(certifications) ? certifications : []
          );

          setKeySkills(Array.isArray(key_skills) ? key_skills : []);

          setTechSkills(
            Array.isArray(technical_skills) ? technical_skills : []
          );

          setCertActSkills(
            typeof certificates_activities_skills === "string"
              ? certificates_activities_skills
              : ""
          );

          setAwardRecogn(
            typeof awards_recognitions === "string"
              ? awards_recognitions
              : ""
          );
    
          if (template) setSelectedTemplate(template);

          setOriginalData({
            fullName: `${contact_details?.first_name} ${contact_details?.last_name}`,
            originalResumeURL: latest_resume?.resume_link,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /* Candidate Detail + Candidate Evaluation */

  const getEmpCandidatesDetailData = async () => {
    if (candidateDetailId !== null && jobDetailId !== null) {
      setLoader(true);
      dispatch(
        getCandidateDetailAsync({
          userId: user?.id,
          candidateId: candidateDetailId,
          jobId: jobDetailId,
        })
      )
        .then((response) => {
          const res = response?.payload;

          const analysisSkills = res?.analysis_skills;
          setJobDetailData(res?.jobDetail);
          setCandidateDetailData(res?.candidate_detail);
          setCandidateData({
            ...candidateData,
            analysis_skills: analysisSkills,
            avatarLink: res?.avatarLink,
            isFavorite: res?.is_favorite,
            currentCompany: res?.lastCompany,
            currentJobTitle: res?.jobTitle,
            email: res?.email,
            education: res?.education?.degree,
            linkedinURL: res?.linkedin,
            workPreferences: res?.work_preferences,
            techTag: res?.tags,
            expectedSalary: res?.expected_salary,
            salaryRange: res?.salary_range,
            currency: res?.currency?.title,
          });
          const tabs = CANDIDATE_Detail_Tab.filter((item) => {
            if (res?.candidate_detail?.include_profile !== 1) {
              return item.name !== "Candidate profile";
            } else {
              return item.name;
            }
          });
          setUserTabs(tabs);
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getEmpCandidatesDetailData();
    getParsedResumeData();
  }, []);

  // Check if candidateDetail and jobDetail are not empty objects
  const isCandidateDetailNotEmpty =
    Object?.keys(candidateDetailData)?.length > 0;
  const isJobDetailNotEmpty = Object?.keys(jobDetailData)?.length > 0;

  const sectionResumePreviewComponents = {
    [RESUME_TEMPLATE_IDS.DEFAULT]: () => (
      <CanResumeTemp
        enableAltText={false}
        userType={USER_TYPE.EMPLOYER}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={contactDetails}
        proExperience={proExperience}
        projects={projects}
        education={education}
        certifications={certifications}
        keySkills={keySkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.STUDENT]: () => (
      <CanResumeTempSTD
        enableAltText={false}
        userType={USER_TYPE.EMPLOYER}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={contactDetails}
        proExperience={proExperience}
        education={education}
        certActSkills={certActSkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.CLASSIC_CORPORATE]: () => (
      <CanResumeTempATS
        enableAltText={false}
        userType={USER_TYPE.EMPLOYER}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={contactDetails}
        keyCompetencies={keyCompetencies}
        proExperience={proExperience}
        projects={projects}
        education={education}
        certificates={certificates}
        certifications={certifications}
        keySkills={keySkills}
        techSkills={techSkills}
      />
    ),
    [RESUME_TEMPLATE_IDS.EXECUTIVE]: () => (
      <CanResumeTempEXE
        enableAltText={false}
        userType={USER_TYPE.EMPLOYER}
        originalData={originalData}
        selectedSection={selectedTemplate?.sections}
        contactDetails={contactDetails}
        proExperience={proExperience}
        education={education}
        certifications={certifications}
        keySkills={keySkills}
        awardRecogn={awardRecogn}
      />
    ),
  };

  return (
    <>
      {isCandidateDetailNotEmpty ? (
        <>
          <section>
            <CanDetailTitle jobDetailData={jobDetailData} />
          </section>
          <section className="journey-sec">
            <div className="container container-site">
              <div className="journey-row">
                {/* <CanDetailProgressBar /> */}
                <CanDetailHead
                  showEmoji={true}
                  showCandidateStatus={true}
                  showSalaryExpectation={true}
                  showWorkPreference={true}
                  showTechTag={true}
                  showSendMsg={true}
                  candidateData={candidateData}
                  candidateDetailData={candidateDetailData}
                  jobDetailData={jobDetailData}
                  onFvtClick={onFvtClick}
                  onFirstImpressionClick={onFirstImpressionClick}
                />
              </div>
              <div className="table-user-permission">
                <CanDetailTab
                  list={userTabs}
                  detailTab={candidateDetailTab}
                  onTabClick={(tab) => dispatch(setCandidateDetailTab(tab))}
                />
                {candidateDetailTab === "resume" && (
                  <div className="tab-content ex1-content" id="ex1-content">
                    <div
                      className="tab-pane fade show active"
                      id="ex1-tabs-1"
                      role="tabpanel"
                      aria-labelledby="ex1-tab-1"
                    >
                      <div className="tabs-content-jour">
                        <div className="text-resume-buttn-j">
                          <p className="content-heading-jour-res">Resume</p>
                          <div className="resume-dwl-pr-btn">
                            <button
                              className="resum-btn-p"
                              onClick={() => handlePreviewResume()}
                            >
                              PDF preview
                            </button>
                          </div>
                        </div>
                        {/* Fixing the syntax issue here */}
                        {selectedTemplate ? (
                          sectionResumePreviewComponents[
                            selectedTemplate?.id
                          ] ? (
                            sectionResumePreviewComponents[
                              selectedTemplate?.id
                            ]()
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {candidateDetailTab === "candidate-evaluation" && (
                  <CanDetailEval
                    showFeeddBack={true}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                  />
                )}
                {candidateDetailTab === "notes" && (
                  <CandidatesNotesSec
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                  />
                )}
                {candidateDetailTab === "candidate-activities" && (
                  <CanDetailActivities
                    appId={candidateDetailData?.application_id}
                  />
                )}
                {/* {candidateDetailTab === "messages-tab" && (
                  <CanDetailMessages
                    showFeeddBack={true}
                    candidateData={candidateData}
                    candidateDetailData={candidateDetailData}
                    jobDetailData={jobDetailData}
                  />
                )} */}
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default EmpCandidateDetail;
